@import "../../variables.module.scss";
#discount-summary {
  // margin-top: -4rem !important; //so it starts from top, same as margin-top from #storewide-sale from StorewideSale.scss is 4rem

  background-color: #eaf0fb !important;
}

.discount-summary-deck {
  margin-top: 15rem;

  // margin-bottom: auto;
}

.storefront-designs-preview-card {
  margin-top: 17rem;

  border-radius: 5px;
  width: 40%;
  padding: 15px 20px;
  margin-left: auto;
  margin-right: auto;
}

.storefront-designs-preview-card2 {
  border-radius: 5px;
  width: 50%;
  padding: 30px 20px;
  margin-left: auto;
  margin-right: auto;
}

.storefront-designs-preview-card3 {
  margin-top: 10rem;
  border-radius: 5px;
  width: 50%;
  padding: 40px 40px;
  margin-left: auto;
  margin-right: auto;

  th,
  td {
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.storefront-designs-preview-card4 {
  margin-top: 30rem;
  border-radius: 5px;
  width: 45%;
  padding: 30px 20px 20px;
  margin-left: auto;
  margin-right: auto;
  input {
    height: 4rem;
    border-width: 0.2rem;
    font-size: 1.3rem;
  }
  ::placeholder {
    font-size: 13px;
    color: #9f9f9f;
  }
  button {
    height: 4rem;
    background-color: #474747;
    color: white;
    font-size: 16px;
  }
  button:hover {
    color: white !important;
  }
}

.collection-page-contents {
  .collection-page-img-div {
    margin-top: -6rem;
  }
}

.product-page-contents {
  .product-page-img-div {
    margin-left: -10rem;
  }
}

// @media only screen and (max-width: 425px) {
//   .discount-summary-deck {
//     margin-top: 5rem;
//   }
// }
