@import "../../variables.module.scss";
.attribute-keyword {
  border: solid lightgray;
  border-width: 1px;
  border-radius: 5px;

  margin-left: auto;
  margin-right: auto;

  -webkit-box-shadow: 0px 5px 5px 0px rgba(169, 164, 248, 0.2);
  -moz-box-shadow: 0px 5px 5px 0px rgba(169, 164, 248, 0.2);
  box-shadow: 0px 5px 5px 0px rgba(169, 164, 248, 0.2);
}
.attribute-keyword-header {
  margin-left: auto;
  margin-right: auto;
}

.containerCustom {
  width: 100%;
  padding: 1.25rem;
}

.attribute {
  width: 35.5%;
  float: left;
  border-radius: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.attribute-input {
  border-radius: 3px;
  height: 3.4rem;
  padding-top: 0.45rem;
  font-weight: 400;
}

.attribute-input-clock {
  height: 3.8rem;
}

.condition {
  width: 30%;
  margin-left: 2%;
  float: left;
}

.keyword {
  width: 24.25%;
  margin-left: 2%;
  float: left;
}

.keyword-input {
  border-radius: 3px;
  font-weight: 300;
}

.attribute-metafield {
  width: 23.45%;
  float: left;
  border-radius: 8px;
  margin-top: auto;
  margin-bottom: auto;
}

.condition-metafield {
  width: 23.45%;
  margin-left: 2%;
  float: left;
}

.filter-by {
  width: 23.45%;
  margin-left: 15px;
  float: left;
}

.keyword-no-metafield {
  width: 30%;
  margin-left: 2%;
  float: left;
}

.keyword-no-metafield-cross {
  width: 26.5%;
  margin-left: 2%;
  float: left;
}

.keyword-with-metafield {
  width: 23.4%;
  margin-left: 2%;
  float: left;
}

.keyword-with-metafield-cross {
  width: 19.75%;
  margin-left: 2%;
  float: left;
}

.remove {
  cursor: pointer;
  float: left;
  width: 1%;
}

.remove-button {
  border: none;
  font-size: large;
  background-color: white;
  margin-top: 0.25rem;
  margin-left: 1rem;
}

.remove-button:hover,
.remove-button:focus {
  outline: none;
}

.view-list {
  text-align: center;
  cursor: default;
}

.view-list-active {
  cursor: pointer;
  color: $icon-active-color;
}

.btn-add-container {
  margin-top: 0.5rem;
  display: flex;
  height: 4rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: $icon-active-color solid 0.2rem;
  border-radius: 5px;
  color: $icon-active-color;
  font-weight: bold;

  transition: all 0.2s;
  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.2);
}

.btn-add-plus {
  font-size: 3rem;
  margin-right: 1rem;
  font-weight: 400;
}

.btn-add-text {
  font-size: 1.3rem;
  font-weight: 600;
}

.btn-add-container:hover {
  //transform: scale(0.98);
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.3);
  color: $icon-active-color;
}

.apply-filter-row {
  margin-top: 2.5rem;
}

.btn-disable-add-container {
  margin-top: 0.5rem;
  display: flex;
  height: 4rem;
  justify-content: center;
  align-items: center;

  border: grey solid 0.2rem;
  border-radius: 5px;
  color: grey;
  font-weight: bold;
}

.btn-disable-add-plus {
  font-size: 3rem;
  margin-right: 1rem;
  font-weight: 400;
}

.btn-disable-add-text {
  font-size: 1.3rem;
  font-weight: 600;
}

.apply-filter-btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );
  font-size: 1.3rem;
  font-weight: 600;
  padding: 6px 0px !important;
  border-radius: 5px;
  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
  transition: all 0.2s;
  height: 4rem;
}

.apply-filter-btn:hover {
  //transform: scale(0.98);
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
}

.reset-filter-btn-container {
  display: flex;
  height: 4rem;
  justify-content: center;
  align-items: center;

  border-radius: 5px;
  color: $icon-active-color;
  font-weight: bold;

  transition: all 0.2s;
  opacity: 1;
}

.reset-filter-btn-cross {
  font-size: 2.5rem;
  margin-right: 1rem;
  padding-bottom: 0.25rem;
}

.reset-filter-btn-text {
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
}

.reset-filter-btn-container:hover {
  //transform: scale(0.98);
  color: $icon-active-color;
  opacity: 0.75;
}

.found-products-text {
  color: $icon-active-color;
}

.heading {
  color: $general-font-color;
}

.save-bulk-btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );

  font-size: 1.3rem;
  font-weight: 600;
  height: 4rem;
  border-radius: 5px;

  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);

  transition: all 0.2s;
}

.save-bulk-btn:hover {
  //transform: scale(0.98);
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
}

.save-bulk-btn-loading {
  background-color: $icon-hover-color !important;
  font-size: 1.3rem;
  font-weight: 600;
  height: 3.25rem;
  pointer-events: none;
  // padding: 10px 0px !important;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 15px 0px rgba(129, 129, 129, 0.4);
  -moz-box-shadow: 0px 10px 15px 0px rgba(129, 129, 129, 0.4);
  box-shadow: 0px 10px 15px 0px rgba(129, 129, 129, 0.4);
}

.save-bulk-btn-div {
  width: 26%;
  margin-bottom: 0.6rem;
}

.product-filter-time {
  font-size: small;
}

.match-view-list-down {
  display: flex;
  flex-direction: column;
  justify-content: "center";
}

.save-btn-modal-container {
  height: 50rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.compare-check {
  height: 1.5rem;
  width: 1.5rem;
}

.Polaris-Scrollable--verticalHasScrolling {
  overflow-y: scroll !important;
}

.Polaris-Scrollable--vertical {
  overflow-y: auto !important;
}

.Polaris-Scrollable--verticalHasScrolling::-webkit-scrollbar {
  display: none !important;
}

.Polaris-Scrollable--vertical::-webkit-scrollbar {
  display: none !important;
}

.svg-modal {
  margin-bottom: 3rem;
}

.svg-icon {
  margin-right: 3rem;
  margin-bottom: -1rem;
}

.modal-heading {
  font-size: 2.2rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
}

.modal-text {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: center;
  margin-bottom: 4rem;
  padding-right: 4rem;
  padding-left: 4rem;
}

.csv-modal-btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );

  font-size: 1.3rem;
  font-weight: 600;
  height: 4rem;
  width: 20rem;
  border-radius: 5px;

  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);

  transition: all 0.2s;
}

.csv-modal-btn:hover {
  //transform: scale(0.98);
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
}

.save-modal-btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );

  font-size: 1.3rem;
  font-weight: 600;
  height: 4rem;
  width: 17rem;
  border-radius: 5px;

  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
  transition: all 0.2s;
}

.save-modal-btn:hover {
  //transform: scale(0.98);
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
}

.modal-btn-extra {
  width: 20rem;
}

.button-secondary {
  height: 36px;
  width: 160px;
  border: none;
  background: #fff;
  font-weight: normal;
  font-size: 14px;
  color: #3553ee;
  cursor: pointer;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#b0bdff),
    to(#e0e5ff)
  ) !important;
  background: -webkit-linear-gradient(top, #d7deff, #f4f6ff) !important;
  background: -moz-linear-gradient(top, #d7deff, #f4f6ff) !important;
  background: linear-gradient(360deg, #d7deff, #f4f6ff) !important;
  border-radius: 4px !important;
  -webkit-transition: all 0.1s !important;
  -moz-transition: all 0.1s !important;
  transition: all 0.1s !important;
  border: 1px solid #c5beea;
}

.cancel-modal-btn {
  color: $icon-active-color;
  font-size: 1.3rem;
  font-weight: 600;
  height: 4rem;
  width: 17rem;
  border-radius: 5px;
  border: none;
  transition: all 0.2s;
  opacity: 1;
}

.cancel-modal-btn:hover {
  //transform: scale(0.98);
  opacity: 0.75;
  color: $icon-active-color;
}

.cancel-modal-btn-extra {
  color: $icon-active-color;
  font-size: 1.3rem;
  font-weight: 600;
  height: 4rem;
  width: 20rem;
  border-radius: 5px;
  border: none;
  transition: all 0.2s;
  opacity: 1;
  display: flex;
  justify-content: center;
}

.cancel-modal-btn-extra:hover {
  //transform: scale(0.98);
  opacity: 0.75;
  color: $icon-active-color;
  text-decoration: none;
}

.cancel-modal-btn-disable {
  color: grey;
  font-size: 1.3rem;
  font-weight: 600;
  height: 4rem;
  width: 13rem;
  border-radius: 5px;
  border: rgb(236, 235, 235) 1px solid;
}

.modal-saved-container {
  height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-saving-container {
  height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-heading-saving {
  font-size: 2.2rem;
  font-weight: 500;
}

.modal-spinner {
  margin-bottom: 2rem;
}

.choose-method-container {
  display: flex;
  justify-content: space-between;
}

.choose-method-btn {
  display: flex;
  height: 4rem;
  width: 48%;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: $icon-active-color solid 2px;
  border-radius: 10rem;
  color: $icon-active-color;
  font-weight: 600;
  font-size: 1.3rem;

  transition: transform 0.1s;
}

.choose-method-btn:hover {
  background-color: #f1f3ff;
  //transform: scale(0.98);
}

.choose-method-btn-active {
  border: none;
  background-image: linear-gradient(
    270deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    270deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );

  display: flex;
  height: 4rem;
  width: 48%;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 10rem;
  color: white;
  font-weight: 600;
  font-size: 1.3rem;

  transition: transform 0.1s;
}

.choose-method-btn-active:hover {
  //transform: scale(0.98);
}

.price-update-type-percentage-con {
  display: flex;
  justify-content: space-between;
}

.price-update-type-percentage {
  display: flex;
  height: 4rem;
  width: 48%;
  justify-content: center;
  align-items: center;
}

// .Polaris-Modal-CloseButton--withoutTitle {
//   left: 0rem;
// }

.Polaris-Icon {
  height: 2.5rem;
  width: 2.5rem;
}

.Polaris-Modal-CloseButton {
  margin-right: -1rem;
  margin-left: 0rem;
  padding: 2.4rem;
}

.MuiPaper-elevation2 {
  box-shadow: none !important;
}

.Polaris-Modal-Header__Title {
  word-wrap: break-word !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
  flex: 1 1 !important;
  margin-top: 2rem !important;
  margin-left: 2rem !important;
}

.Polaris-Modal-Header {
  border-bottom-width: none !important;
  border-bottom-style: none !important;
  border-bottom-color: none !important;
  padding: 1rem 1rem 0rem 1rem !important;
}

.MuiInputBase-root {
  background-color: #f5f6f9 !important;
}

.MuiSvgIcon-root {
  fill: #203eca !important;
}

.MuiInput-underline:before {
  height: 3rem !important;
  border: 1px solid #203eca !important;
  border-radius: 0.5rem !important;
}

.MuiToolbar-root {
  display: flex !important;
  position: relative !important;
  align-items: center !important;
}

.MTableToolbar-root-27 {
  margin-top: -6.5rem !important;
  margin-left: 60rem !important;
  z-index: 1 !important;
  position: fixed !important;
}

.MuiInputAdornment-positionEnd {
  display: none !important;
}

.MuiInputBase-input {
  font-size: 1.6rem !important;
  font: none !important;
}

.MuiSvgIcon-fontSizeSmall {
  font-size: 2.25rem !important;
}

// .view-list-container{
//   padding-left: 3rem;
//   padding-right: 3rem;
//   height: 10rem;
// }

.vl-topbar {
  display: flex;
  border: 1px solid red;
  justify-content: space-between;
  align-items: center;
  margin-right: 10rem;
  margin-left: 5rem;
  // height: 6rem;
}

.vl-group-variant-searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: blue;
}

.MuiToolbar-root {
  display: none !important;
  position: relative;
  align-items: center;
}

// .vl-title{
//   border: 1px solid blue;
// }

// .vl-group-variant{
//   border: 1px solid blue;
// }

.vl-searchbar {
  border: 1px solid blue;
  border-radius: 0.5rem;
}

.vl-table-container {
  border: 1px solid blue;
}

.manualProductContainer {
  display: flex;
  justify-content: space-between;
}

.manualProductSvg {
  margin-right: 1rem;
}

.manualProductLabel {
  margin-left: 1rem;
  padding-top: 0.3rem;
}

.transferListContainer {
  display: flex;
  flex-grow: 1;
}

.impacted-heading {
  display: flex;
  justify-content: center;
}

.matchingProductSearch {
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-bottom: 0rem;
}

.matchingProductSearchInput {
  padding: 1rem;
  border: lightgray 1px solid;
  border-radius: 0.5rem;
  flex: 1;
}

.transferListLeftWrapper {
  margin-bottom: 1rem;
}

.transferListLeft {
  flex: 1;
  background-color: #f1f3fb;
  border-radius: 0.5rem;
  overflow-y: hidden;
  height: 37rem;
}

// .transferListLeft::-webkit-scrollbar {
//   display: none;
// }

.transferListRight {
  flex: 1;
  background-color: #f1f3fb;
  border-radius: 0.5rem;
  overflow-y: hidden;
  height: 37rem;
}

// .transferListRight::-webkit-scrollbar {
//   display: none;
// }

.verticalLine {
  border-left: 5px solid #d4def5;
  height: 7rem;
  margin-left: 2rem;
  border-radius: 0.25rem;
  float: left;
}

.product-manual-card-uncheck {
  margin: 1rem;
  background-color: white;
  border: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  border-radius: 0.5rem;
  height: 7rem;
  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  cursor: pointer;
  transition: transform 0.1s;
  margin-left: 4rem;
}

.product-manual-card-uncheck:hover {
  //transform: scale(0.98);
  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
}

.product-manual-card {
  margin: 1rem;
  background-color: white;
  border: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  border-radius: 0.5rem;
  height: 10em;
  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  cursor: pointer;
  transition: transform 0.1s;
}

.product-manual-card-chrome {
  margin: 1rem;
  background-color: white;
  border: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem;
  border-radius: 0.5rem;
  height: 10em;
  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  cursor: pointer;
  transition: transform 0.1s;
}
.product-manual-card-chrome:hover {
  //transform: scale(0.98);
  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
}

.product-manual-card:hover {
  //transform: scale(0.98);
  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
}

.product-manual-card-logo-title-con {
  display: flex;
  align-items: center;
  max-width: 97%;
  text-overflow: ellipsis;
}

.product-manual-card-logo {
  background-color: #bcc3d5;
  height: 7em;
  width: 7em;
  min-width: 6.5em;
  border-radius: 0.3rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.product-manual-card-logo-variant {
  background-color: #bcc3d5;
  height: 6em;
  width: 6em;
  min-width: 6em;
  border-radius: 0.3rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.product-manual-card-img {
  height: 100%;
  width: 100%;
}

.product-manual-card-img-variant {
  height: 100%;
  width: 100%;
}

.product-manual-card-logo-loader {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.transferListLabelLeft {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-right: 1.25rem;
  margin-bottom: 1rem;
}

.transferListLabelRight {
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-left: 1.25rem;
  margin-bottom: 1rem;
}

.transfer-list-text {
  font-weight: 400;
  color: #4765f0;
}

.transfer-list-heading {
  font-size: 1.3rem;
  font-weight: 400;
}

.transferListLabelBottom {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 1.5rem;
  color: #4765f0;
}

.transferListLabelBottomHover {
  transition: transform 0.1s;
  cursor: pointer;
}

.transferListLabelBottomHover:hover {
  transform: scale(0.98);
}
.hide-select-option {
  display: none;
}

.transter-list-card-text {
  font-size: 1.3rem;
  font-weight: 400;
  width: 30rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0.75rem;
}

.transter-list-card-text-title {
  font-size: 1.3rem;
  font-weight: 600;
  width: 30rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 0.75rem;
}

.transter-list-card-variant {
  color: $icon-active-color;
}

.transter-list-card-heading {
  font-size: 1.3rem;
  font-weight: 500;
}

.bulk-summary-container-heading {
  text-align: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  font-size: 1.5rem;
  color: $icon-active-color;
  font-weight: 500;
}

.bulk-summary-font {
  font-size: 1.3rem;
  font-weight: 400;
}

.bulk-summary-sub-heading {
  font-size: 1.3rem;
  font-weight: 400;
}

.bulk-summary-font-color {
  color: $icon-active-color;
}

.summary-container {
  background-color: white;
  position: fixed;
  width: 48rem;
  right: 1.5%;
  top: 33%;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  // -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
}
.summary-container-bulk {
  background-color: white;
  position: fixed;
  width: 30%;
  right: 1.5%;
  top: 33%;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  // -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
}

.summary-section-divider {
  border-top: black solid 1px;
}

.summary-png-div {
  margin-top: -5rem;
}

// .summary-emoji {
//   margin-top: -120px;
// }

.summary-divider-hr {
  border-bottom: lightgray solid 1px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 3rem;
  margin-left: 3rem;
}

.summary-row-text-center {
  text-align: center;
  word-break: break-word;
}

.summary-col {
  margin-top: -3rem;
  background-color: #eaf0fb;
  border-left: solid white 0.25rem;
  //min-height: 100vh;
}

.summary-col-extend {
  margin-top: -3rem;
  background-color: #eaf0fb;
  border-left: solid white 0.25rem;
  //min-height: 100vh;
}

.meta-label-margin {
  margin-left: -0.9rem;
}

.non-meta-label-margin {
  margin-left: 2%;
}

.non-meta-label-margin-value {
  margin-left: 4.25rem;
}

.currency-input {
  padding-left: 30px;
  padding-top: 4px;
  z-index: 0 !important;
}

@media only screen and (max-width: 1700px) {
  .product-manual-card {
    height: 8em;
  }

  .product-manual-card-chrome {
    height: 8em;
  }

  .product-manual-card-logo {
    height: 6em;
    width: 6.5em;
  }
}

@media only screen and (max-width: 575px) {
  .product-manual-card-chrome {
    height: 10em;
  }
  .product-fitler-labels {
    white-space: nowrap !important;
    max-width: 100%;
    overflow: auto;
  }

  .product-fitler-labels-inner {
    float: none !important;
    display: inline-block !important;
  }

  .meta-filterby-heading {
    margin-left: 2.5rem;
  }

  .meta-condition-heading {
    margin-left: 3rem;
  }

  .meta-value-heading {
    margin-left: 2.25rem;
  }

  .Polaris-Modal-CloseButton {
    padding: 3rem;
  }
  .currency-input {
    padding-top: 5px !important;
  }
}

@media only screen and (max-width: 991px) {
  .summary-col {
    display: none;
  }

  .summary-col-extend {
    display: none;
  }

  .save-bulk-btn {
    font-weight: 400;
  }

  .btn-add-text {
    font-weight: 400;
  }

  .btn-disable-add-text {
    font-weight: 400;
  }

  .reset-filter-btn-text {
    font-weight: 400;
  }

  .save-bulk-btn {
    font-weight: 400;
  }

  .save-bulk-btn-loading {
    font-weight: 400;
  }

  .csv-modal-btn {
    font-weight: 400;
  }

  .save-modal-btn {
    font-weight: 400;
  }

  .cancel-modal-btn {
    font-weight: 400;
  }

  .cancel-modal-btn-disable {
    font-weight: 400;
  }

  .choose-method-btn {
    font-weight: 400;
  }

  .choose-method-btn-active {
    font-weight: 400;
  }

  .apply-filter-btn {
    font-weight: 400;
  }

  .attribute-input {
    font-weight: 300;
  }

  .transter-list-card-text {
    font-size: 1.1rem;
    font-weight: 300;
    width: 8rem;
  }
}

/*
@media only screen and (max-width: 1180px) {
  .summary-container{
    width: 44rem;
  }
  .summary-container-bulk{
    width: 44rem;
  }
}
*/

@media only screen and (min-width: 1680px) {
  .non-meta-label-margin {
    margin-left: 4.5rem;
  }
  .non-meta-label-margin-value {
    margin-left: 5.5rem;
  }
}
@media only screen and (max-width: 400px) {
  .non-meta-label-margin {
    //margin-left: 4.5rem;
  }
  .non-meta-label-margin-value {
    margin-left: 1.5rem;
  }
}

@media only screen and (max-width: 1000px) {
  .save-bulk-btn-div {
    width: 32%;
  }
  .save-bulk-btn {
    height: 36px;
  }
}

@media only screen and (max-width: 500px) {
  .save-bulk-btn-div {
    width: 42%;
  }
  .save-bulk-btn {
    height: auto !important;
    min-height: 46px !important;
    padding: 6px 6px !important;
  }
}

.storewide-sale--row {
  height: 100%;
}
.storewide-sale {
  height: 85%;
}
/*  @media only screen and (min-width: 1628px){
    .storewide-sale--row{
      min-height: 85vh;
    }
  }*/

/*  @media only screen and (min-width: 1344px) and (max-width: 1628px){
    .summary-container{
      width: 58rem;
    }
    .summary-container-bulk{
      width: 58rem;
    }
  }*/
/*@media only screen and (min-width: 1680px) and (max-width: 1903px){
    .summary-container{
      //width: 49.5rem;
      width: 30%;
    }
}
@media only screen and (min-width: 1904px) and (max-width: 2274px){
    .summary-container{
      width: 72.5rem;
    }
  .summary-container-bulk{
    //width: 64.5rem;
    width: 30%
  }
}*/
@media only screen and (min-width: 2275px) {
  /*   .summary-container{
      width: 95.5rem;
    }
  .summary-container-bulk{
      width: 30%;
      //width: 71.5rem;
    }*/
  .non-meta-label-margin {
    margin-left: 6.5rem;
  }
  .non-meta-label-margin-value {
    margin-left: 13.25rem;
  }
}

@media only screen and (max-height: 620px) {
  .summary-container-bulk {
    width: 100% !important;
    position: relative;
  }
}

@media only screen and (min-width: 1400px) {
  .currency-input {
    // padding-left: 25px !important;
    z-index: 0 !important;
  }
}
@media only screen and (max-width: 900px) and (min-width: 575px) {
  .currency-input {
    // padding-left: 25px !important;
    z-index: 0 !important;
  }
}
