@import "../../variables.module.scss";
.grid-label-wrapper {
  padding-bottom: 2rem;
}

.main-signup-form {
  margin-left: 25rem;
  margin-right: 8rem;
  margin-top: 3rem;
}

.title-container {
  display: flex !important;
  flex-direction: row;
  font-size: 12px;

  .new-form-button {
    border: none;
    background-image: linear-gradient(
      90deg,
      #203eca,
      #2542ef 10%,
      #4b69f7 75%,
      #7190fe
    );
    background-image: -webkit-linear-gradient(
      90deg,
      #203eca,
      #2542ef 0,
      #4b69f7 37%,
      #7190fe
    );

    padding: 0px 20px;

    height: 3.6rem;
    font-size: 1.4rem;
    font-weight: 600;
    // padding: 10px 0px !important;
    border-radius: 5px;
    // margin-bottom: 1rem;

    -webkit-box-shadow: 0px 20px 20px -10px rgba(35, 43, 99, 0.4);
    -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
    box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);

    transition: transform 0.1s;
  }

  .new-form-button:hover {
    //transform: scale(0.98);

    -webkit-box-shadow: 0px 20px 20px -10px rgba(35, 43, 99, 0.3);
    -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
    box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  }

  .new-form-icn {
    font-weight: 600;
    font-size: 1.4rem;
    // padding: 5px;
    margin-right: 1rem;
  }

  .signup-form-btn-svg {
    margin-bottom: 0.25rem;
  }
}

.signup-tab-nav {
  border-bottom: 3px solid #e6ebf3 !important;
  margin-bottom: 1rem;
  cursor: pointer;

  .nav-item {
    .nav-link {
      // padding: 0.5rem 0.5rem !important;
      font-size: 1.4rem !important;
      color: $tab-heading-color !important;
      background-color: transparent !important;
      // font-weight: bold;

      .badge {
        margin-left: 5px;
      }
    }
    .active {
      color: #4155f0 !important;
      border: none !important;
      border-bottom: 2px solid $icon-active-color !important;
      background-color: transparent !important;
      // font-weight: bold;
    }
  }
}

.current-form-grid-label {
  display: flex;
  padding-left: 12px !important;
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  margin-right: auto;
  margin-left: auto;

  h4 {
    font-size: 12px !important;
  }
  .discount-label-discount--left {
    margin-left: 1rem !important;
  }
}

.signup-forms-grid {
  .action-icon {
    margin: 0 5px !important;
  }

  .action-icon-wrapper {
    padding-right: 0 !important;
  }
}

.tab-data {
  padding-top: 10px !important;

  .form-table {
    border-collapse: separate !important;
    border-spacing: 0 20px !important;

    thead {
      th {
        color: $table-heading-color !important;
      }
    }

    tbody {
      tr {
        border-radius: 5px !important;
        background-color: white;
        border-color: coral;
        td {
          padding: 2rem 0.75rem !important;
          position: relative;

          border-top: 1px solid #dfe6f3 !important;
          border-bottom: 1px solid #dfe6f3 !important;

          vertical-align: middle;

          strong {
            font-size: 12px;
          }
        }
        td:first-child {
          border-top-left-radius: 5px !important;
          border-bottom-left-radius: 5px !important;
          border-left: 1px solid #dfe6f3 !important;
        }
        td:last-child {
          border-top-right-radius: 5px !important;
          border-bottom-right-radius: 5px !important;

          border-right: 1px solid #dfe6f3 !important;
        }
      }
    }
  }

  .form-link {
    a {
      color: $icon-active-color !important;
      font-weight: bold;
    }
  }
}

/* toggle button start */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  // Position when unchecked
  position: absolute;
  content: "";
  height: 18px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );
}

input:focus + .slider {
  box-shadow: 0 0 1px #4765f0;
}

input:checked + .slider:before {
  // Slider Initial Position when checked
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* toggle button end */

// @media only screen and (max-width:  1024px) {
//   .grid-value-container {
//     display: flex !important;
//     justify-content: space-between !important;
//     // max-width: 100% !important;
//     flex: 0 0 100%;
//   }
//   .signup-forms-grid .action-icon {
//     margin: 0 !important;
//     height: 12px !important;

//   }

//     label {
//       margin: 0 5px  !important;
//       width: 35px !important;
//       height: 20px !important;

//       .slider:before {
//        height: 15px !important;
//        width: 15px !important;
//      }
//     }

//   }
//   @media only screen and (max-width: 768px) {
//     .title-container {
//       h1 {
//         font-size: 2rem !important;

//       }
//       .new-form-button {
//         font-size: 14px !important;
//         padding: 0 20px !important;

//         svg {
//           height: 14px !important;
//           margin-bottom: 2px !important;
//         }
//       }
//     }

//     .grid-value-container{
//       max-width: 100% !important;
//     }
//     // .hidden-label {
//     //   h3 {
//     //     font-size: 16px !important;
//     //   }
//     // }

//   }

//   @media only screen and (max-width: 600px) {
//     .grid-value-container {
//       display: block !important;

//       div:last-child {
//         margin: 5px 0;
//       }
//     }

//     .hidden-label {
//       margin: 5px 0 !important;
//       // h3 {
//       //   font-size: 16px !important;
//       // }

//     }

//   }

// @media only screen and (max-width: 425px) {
//   .title-container {
//     .new-form-button {
//       padding: 0px 15px !important;
//       font-size: 10px !important;

//       .new-form-icn {
//         font-size: 10px !important;

//         svg {
//           height: 10px;
//         }
//       }
//     }
//   }

//   .signup-tab-nav {
//     .nav-item {
//       .nav-link {
//         padding: 1.5rem 2rem !important;
//         font-size: 10px !important;
//       }
//     }
//   }
//   .tab-data {
//     padding-top: 0px !important;
//   }
// }

// @media only screen and (max-width: 375px) {
//   .title-container {
//     h1 {
//       font-size: 14px !important;
//     }

//     .new-form-button {
//       padding: 0px 10px !important;

//       .new-form-icn {

//         svg {
//           height: 10px !important;
//         }
//       }
//     }
//   }
// }

// @media only screen and (max-width: 320px) {
//   .title-container {

//     flex-direction: column !important;
//     text-align: center !important;

//   }
//   .signup-tab-nav {
//     justify-content: space-between !important;
//   }
// }
