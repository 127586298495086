@import "../../variables.module.scss";
.storefront-designs-settings {
  width: 89%;
  margin-bottom: 100px;
  // margin-left: 15rem;
}

.position-sticky {
  background-color: #f6f6f6;
}

.storefront-settings-card {
  padding: 20px 25px;
  border-radius: 5px;
  margin-right: 6rem;
  background-color: white;
  // background-color:rgba(255, 255, 255, 0.6);
  border-color: white;
  border-width: 0.25rem;

  -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);

  h2 {
    color: $general-font-color;
    font-weight: 600;
  }
  // h3 {
  //   color: $general-font-color;
  //   font-weight: 600;
  //   font-size: 1.5rem;
  // }
  h4 {
    color: $general-font-color;
    font-weight: bold;
  }
  .text--lead {
    font-size: 1.5rem;
  }
  input {
    height: 3.4rem;
    font-size: 1.3rem;
    font-weight: 400;
    color: black;
  }

  .toggle-collapse:hover {
    cursor: pointer;
  }

  .btn-update-placement {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1.3rem;
    font-weight: 600;
    color: $outline-btn-color;
    border-color: $outline-btn-color;
    -webkit-box-shadow: 0px 10px 15px 0px rgba(169, 164, 248, 0.2);
    -moz-box-shadow: 0px 10px 15px 0px rgba(169, 164, 248, 0.2);
    box-shadow: 0px 10px 15px 0px rgba(169, 164, 248, 0.2);
  }
  .btn-update-placement:hover {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    font-size: 1.3rem;
    font-weight: 600;
    color: white;
    border-color: $outline-btn-color;
    background-color: $outline-btn-color;
  }

  .hr--full-width {
    margin-left: -25px;
    margin-right: -25px;
  }

  .toggle-block--mt {
    margin-top: 2.5rem;
  }

  /* toggle button start */
  .switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 26px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    // Position when unchecked
    position: absolute;
    content: "";
    height: 18px;
    width: 20px;
    left: 3px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-image: linear-gradient(
      90deg,
      #203eca,
      #2542ef 10%,
      #4b69f7 75%,
      #7190fe
    );
    background-image: -webkit-linear-gradient(
      90deg,
      #203eca,
      #2542ef 0,
      #4b69f7 37%,
      #7190fe
    );
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #4765f0;
  }

  input:checked + .slider:before {
    // Slider Initial Position when checked
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  /* toggle button end */
}

#strikeout-price-tab {
  a.nav-link.active {
    border: none;
    color: #425ae7 !important;
  }

  a {
    font-size: 1.6rem;
    font-weight: bold;
  }

  a.nav-link {
    color: #7084b3 !important;
  }

  a.nav-link:hover {
    color: #43598b !important;
    border-color: transparent !important;
    cursor: pointer !important;
  }

  a.nav-link.active::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 6px 0 6px;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    position: absolute;
    left: 50%;
    bottom: -13px;
  }

  a.nav-link.active::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 7px 0 7px;
    border-color: #99a3af transparent transparent transparent;
    position: absolute;
    margin-top: 0px;
    margin-left: 0px;
    position: absolute;
    left: calc(50% - 1px);
    bottom: -15px;
  }

  .nav-item {
    position: relative;
    margin-bottom: 5px !important;
  }

  border-bottom: 1px solid #99a3af !important;
  -webkit-box-shadow: 0px 15px 15px -15px rgba(169, 164, 248, 0.3);
  box-shadow: 0px 15px 15px -15px rgba(169, 164, 248, 0.3);
}

.step-3-subheading {
  font-size: 1.3rem;
  font-weight: 400;
  color: grey;
}

.step-3-liquid-code {
  border: rgb(199, 197, 197) solid 1px;
  background-color: rgb(241, 239, 239);
  font-size: 1.3rem;
  padding: 1.25rem;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}

.clock-type-font {
  font-size: 1.5rem;
}

.save-bulk-btn-sale-clock {
  right: 0rem;
  margin-top: 2rem;
}

.clock-style-toggle {
  margin-right: 0.5rem;
}

.input-heading-clock-style {
  font-size: 1.3rem;
  font-weight: 400;
}

.Polaris-Connected__Item--primary {
  border: 0.1rem solid #c4cdd5 !important;
}

.Polaris-TextField__Backdrop {
  border: none !important;
}
