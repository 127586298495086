@import "../../variables.module.scss";
.create-a-sale-active-date-time-container {
  display: flex;
  justify-content: space-between;
}

.div-not-clickable {
  pointer-events: none;
}

.endTimeDateToggle {
  margin-top: 0.75rem;
  margin-left: 0.5rem;
}

.endTimeDateToggleLabel {
  margin-top: 0.5rem;
}

.activeTimeDateToggle {
  margin-top: -0.8rem;
}

.manualProductToggle {
  margin-top: -0.75rem;
  margin-bottom: -0.8rem;
}

.create-sale-timedate-container-toggle {
  margin-right: 0.25rem;
}

.group-product-variants {
  margin-bottom: 1rem;
  display: flex;
  justify-content: end;
}

.group-product-variants-label {
  margin-top: 1rem;
}

.current-discount-action {
  display: flex;
}

.MuiSnackbarContent-message {
  font-size: 1.5rem;
}

.status-style {
  display: flex;
  justify-content: center;
  margin-left: 30px;
}

.priotiy-style {
  display: flex;
  justify-content: center;
}

.priotiy-content-style {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .priotiy-content-style {
    display: flex;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1260px) {
  .priotiy-style {
    display: flex;
    justify-content: center;
    margin-left: 55px;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1500px) {
  .left-margin {
    // margin-left: -10px;
  }
}

@media only screen and (min-width: 2200px) and (max-width: 2460px) {
  .left-margin {
    // margin-left: 10px;
  }
}

@media only screen and (min-width: 2460px) and (max-width: 3000px) {
  .left-margin {
    // margin-left: 30px;
  }
}

@media only screen and (min-width: 3000px) and (max-width: 3500px) {
  .left-margin {
    // margin-left: 45px;
  }
}

@media only screen and (min-width: 3500px) and (max-width: 3900px) {
  .left-margin {
    // margin-left: 55px;
  }
}
