@import "../../variables.module.scss";
#storefront-designs {
  margin-left: 14rem;
  margin-top: 3rem;
}
.save-storefront-designs-settings-btn-div {
  width: 25%;
}

.save-storefront-design-btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );

  font-size: 1.3rem;
  font-weight: 600;
  height: 3.5rem;
  border-radius: 5px;

  -webkit-box-shadow: 0px 20px 20px -10px rgba(35, 43, 99, 0.4);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);

  transition: transform 0.1s;
}

.save-storefront-design-btn:hover {
  //transform: scale(0.98);
  -webkit-box-shadow: 0px 20px 20px -10px rgba(35, 43, 99, 0.3);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
}

// @media only screen and (max-width: 575px) {
//   #storefront-designs {
//     margin-left: 3rem ;
//     margin-top: 3rem;
//   }
// }
