@import "../../variables.module.scss";
#discount-summary {
  // margin-top: -4rem !important; //so it starts from top, same as margin-top from #storewide-sale from StorewideSale.scss is 4rem

  background-color: #e0e5ec !important;
}

.discount-summary-deck {
  margin-top: 15rem;
  // margin-bottom: auto;
}

.card-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0; /* required */
  // position:fixed;
  // z-index: 15;
  // width: 50rem;
  // right: 150rem;
  // top: 30rem;
  // left:50rem;
  // height: 40rem;
}

.discount-summary-card {
  // background-color:rgba(255, 255, 255, 0.6);

  background-color: white;
  border-color: white;
  border-width: 0.25rem;
  border-radius: 5px;
  width: 55%;
  padding: 15px 20px;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  p,
  li {
    font-size: 1.5rem;
  }

  .sub-heading {
    color: $icon-active-color;
    font-size: 1.6rem;
    font-weight: 600;
  }

  ul {
    padding-left: 20px;
  }
}

.discount-summary-card-2 {
  border-color: #4b69f7;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );
  h1 {
    font-weight: bold;
    font-size: 3rem;
  }
  h1,
  p {
    color: white;
  }
}

.summary-colomn-height {
  height: 105vh;
}

// @media only screen and (max-width: 1024px) {
//   .discount-summary-card-2 {

//     h1 {
//       font-size: 2rem !important;
//     }
//   }
// }

// @media only screen and (max-width: 425px) {
//   .discount-summary-deck {
//     margin-top: 5rem;
//   }

// }

// @media only screen and (max-width: 390px) {
//   .discount-summary-card {

//     width: 90%;
//     padding: 15px 20px;

//     p,
//     li {
//       font-size: 1.4rem;
//     }

//     .sub-heading{
//       color: $icon-active-color;
//       font-size: 1.6rem;
//       font-weight: 600;
//     }

//     ul {
//       padding-left: 20px;
//     }
//   }

//   .summary-colomn-height{
//     height: 40vh;
//   }
// }
