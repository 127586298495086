@import "../variables.module.scss";
.main-analytics {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-top: 3rem;
}

.top-card {
  border-radius: 5px;
  // padding: 60px;
  // padding-top: 5rem;
  // padding-bottom: 30px;
  // text-align: center;
  // align-items: center;
  // align-content: center;
  // width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 12rem;
  // width: 25rem;

  border-color: white;
  border-width: 0.25rem;
  -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
}

.top-card-title {
  font-size: 2.2rem;
  font-weight: 700;
  color: $general-font-color;
}

.top-card-body {
  font-size: 1.3rem;
  font-weight: 400;
  white-space: nowrap;
}

.bottom-card {
  border-radius: 5px;
  height: 35rem;

  border-color: white;
  border-width: 0.25rem;
  -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
}

.bottom-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: $general-font-color;
}

.bottom-card-body {
  font-size: 1.5rem;
  color: $general-font-color;
}

.bottom-card-body-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
}

.analytics-cards-section {
  margin-top: 3rem;
}

@media only screen and (max-width: 575px) {
  .bottom-card {
    height: 20rem;
  }
}

@media only screen and (max-width: 991px) {
  .top-card-title {
    font-weight: 500;
  }

  .top-card-body {
    font-weight: 300;
  }
}

//   @media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
// @media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
// @media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
// @media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
// @media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
// @media (min-width:1281px) { /* hi-res laptops and desktops */ }
