$icon-active-color: #4765f0;
$icon-hover-color: #4765f0;
$icon-inactive-color: #a0b6e4;
$general-font-color: #081e57;
$outline-btn-color: #4454c7;

$tab-heading-color: #6c84b7;
$table-heading-color: #003d87;
$table-row-border-color: #dfe6f3;

$subscription-text-color: #001556;
@import "../../variables.module.scss";
.image-block2 img {
  height: 300px;
}
.bg-image-modal {
  background-size: contain;
}
.cover {
  background-size: auto !important;
}
@media only screen and (min-width: 431px) and (max-width: 800px) {
  .image-block2 img {
    height: 160px !important;
    max-height: 288px !important;
  }
  .sub-modal .image-block2 img {
    margin-left: -30px !important;
  }
}
@media only screen and (max-width: 505px) {
  .cover {
    background-size: contain !important;
  }
  .image-block2 img {
    height: 160px !important;
    margin-left: 0 !important;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1050px) {
  .cover {
    background-size: contain !important;
  }
  .bg-image-modal {
    background-size: auto !important;
  }
}

// @media only screen and (min-width: 431px) and (max-width: 800px) {
//   .image-block2 img {
//     height: 236px !important;
//   }
//   .sub-modal .image-block2 img {
//     margin-left: -25px !important;
//   }
// }
// **************************
//  subscription modal design
//***************************
.ft-sub-modal {
  border-radius: 2rem;
  max-width: 885px;
  height: 510px;
  background-color: #fff;
}

.ft-sub-modal-content {
  padding: 4rem 4rem 4rem 0rem;

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.017em;
    color: $subscription-text-color;
    margin-bottom: 2rem;
    .sub {
      line-height: 145%;
      font-size: 24px;
      display: block;
      font-weight: 500;
    }
    .bold {
      line-height: 145%;
      font-size: 29px;
    }
  }
  p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -0.017em;
    font-style: normal;
    font-weight: 400;
    color: #7a8092;
    margin-top: 2rem;
  }
  .ft-confirmation {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -0.017em;
    font-style: normal;
    font-weight: 400;
    color: $subscription-text-color;
  }
}

.ft-action {
  display: flex;
  margin: auto;
  margin-top: 3rem;
  gap: 40px;

  .ft-modal-primary-button {
    border: none;
    background-image: linear-gradient(
      90deg,
      #203eca,
      #2542ef 10%,
      #4b69f7 75%,
      #7190fe
    );
    background-image: -webkit-linear-gradient(
      90deg,
      #203eca,
      #2542ef 0,
      #4b69f7 37%,
      #7190fe
    );
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    width: 270px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
    color: #fff;
    transition: all 0.2s;
    padding: 0;

    &:hover {
      box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
    }
  }

  .ft-modal-secondary-button {
    color: #3353ef;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    background: none;
    border: none;
  }
}

@media only screen and (max-width: 800px) {
  .ft-sub-modal-content {
    padding: 8rem !important;

    h3 {
      margin-bottom: 2rem;
      .sub {
        line-height: 160%;
        font-size: 22px;
      }
      .bold {
        line-height: 150%;
        font-size: 18px;
      }
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
    .ft-confirmation {
      font-size: 14px;
      line-height: 26px;
    }
  }
}

@media only screen and (max-width: 790px) {
  .hide-xs {
    // display: none !important;
  }
}

.row_custom {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}
.modal-img {
  margin-left: -120px;
}
// .first-col {
//   min-width: 40%;
// }
// .second-col {
// }
@media only screen and (max-width: 505px) {
  // .modal-img {
  //   height: 210px !important;
  //   margin-left: 0px !important;
  // }
  // .first-col {
  //   min-width: 38%;
  // }
}
@media only screen and (max-width: 430px) {
  .bg-image-modal {
    background-size: auto !important;
  }
  .sub-modal {
    margin: 0px 0px !important;
    border-radius: 0px !important;
    // padding-right: 15px !important;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1050px) {
  .bg-image-modal {
    background-size: contain !important;
  }
  // .cover {
  //   background-size: auto !important;
  // }
}

.subscription-wrapper {
  padding: 6rem 3rem;
}
.sub-title {
}
.modal-primary-button2 {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef,
    #4b69f7 37%,
    #7190fe
  );
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  min-height: 4rem;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 20px 20px -12pxrgba (23, 52, 251, 0.4);
  color: #fff;
  transition: all 0.2s;
  padding: 0 1rem;
}
.modal-secondary-button2 {
}
.row2 {
  display: flex;
  /* flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
}
.sub-subtitle {
  h2 {
    color: $subscription-text-color;
    font-style: normal;
    font-weight: 600px;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.017em;
  }

  p {
    color: $subscription-text-color;
    font-style: normal;
    font-weight: 400px;
    font-size: 29px;
    line-height: 145%;
    letter-spacing: -0.017em;
  }
}

// .sub-plan {
//   position: relative;
//   padding-bottom: 2rem;
//   .plan {
//     color: $subscription-text-color;
//     font-weight: 700;
//     font-size: 25px;
//     line-height: 38px;
//     text-transform: uppercase;
//   }
//   .amount {
//     font-size: 40px;
//     color: $subscription-text-color;
//     text-transform: capitalize;
//     line-height: 60px;
//     font-weight: 700;
//   }
//   .per {
//     margin-left: 1rem;
//     font-size: 20px;
//     font-weight: 400;
//     line-height: 30px;
//     text-transform: capitalize;
//     color: $subscription-text-color;
//   }

//   img {
//     position: absolute;
//     top: -40px;
//     left: -22px;
//     background: #f9fafb;
//     padding: 0 15px;
//   }

//   button {
//     border: none;
//     background-image: linear-gradient(
//       90deg,
//       #203eca,
//       #2542ef 10%,
//       #4b69f7 75%,
//       #7190fe
//     );
//     background-image: -webkit-linear-gradient(
//       90deg,
//       #203eca,
//       #2542ef 0,
//       #4b69f7 37%,
//       #7190fe
//     );
//     box-sizing: border-box;
//     font-family: "Poppins";
//     font-size: 16px;
//     font-weight: 500;
//     height: 4rem;
//     // width: 276px;
//     border-radius: 5px;
//     text-align: center;
//     box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
//     color: #fff;
//     transition: all 0.2s;
//     padding: 0 1rem;

//     &:hover {
//       box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
//     }
//   }
//   button:disabled,
//   button[disabled] {
//     opacity: 0.4;
//   }
// }

// .sub-item-wrapper {
//   display: flex;
//   flex-direction: row;

//   .sub-item-icon {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     padding: 8px 20px 8px 0;
//     width: 46px;
//   }
//   .sub-item-text {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     padding-left: 8px;

//     h4 {
//       font-family: "Poppins";
//       color: $subscription-text-color;
//       font-size: 18px;
//       font-weight: 600;
//       line-height: 27px;
//       letter-spacing: -0.017em;
//     }
//     p {
//       color: $subscription-text-color;
//       font-weight: 400;
//       font-size: 14px;
//       line-height: 21px;
//       letter-spacing: -0.017em;
//     }
//   }
// }

// .sub-item-data {
//   height: 100%;
//   display: flex;
//   align-items: center;
//   font-family: "Poppins";
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 24px;
//   letter-spacing: -0.017em;
//   color: #4155f0;
// }

@media only screen and (max-width: 1600px) {
  .sub-subtitle {
    h2 {
      font-size: 30px;
      line-height: 50px;
    }

    p {
      font-size: 16px;
    }
  }

  // .sub-plan {
  //   padding-bottom: 2rem;
  //   .plan {
  //     font-size: 16px;
  //     line-height: 26px;
  //   }
  //   .amount {
  //     font-size: 24px;
  //     line-height: 32px;
  //   }
  //   .per {
  //     margin-left: 0.5rem;
  //     font-size: 12px;
  //     line-height: 10px;
  //   }

  //   img {
  //     top: -21px;
  //     left: -16px;
  //     height: 19px;
  //   }

  //   button {
  //     margin-top: 0.5rem;
  //     font-size: 12px;
  //     font-weight: 500;
  //     height: 3rem;
  //   }
  // }

  // .sub-item-wrapper {
  //   .sub-item-icon {
  //     width: 40px;
  //   }
  //   .sub-item-text {
  //     padding-left: 8px;

  //     h4 {
  //       font-size: 18px;
  //       line-height: 22px;
  //     }
  //     p {
  //       font-size: 14px;
  //       line-height: 15px;
  //     }
  //   }
  // }
  // .sub-item-data {
  //   font-size: 14px;
  // }
}

@media only screen and (max-width: 800px) {
  .sub-subtitle {
    h2 {
      font-size: 25px;
      line-height: 40px;
    }

    p {
      font-size: 16px;
    }
  }

  // .sub-plan {
  //   padding-bottom: 2rem;
  //   .plan {
  //     font-size: 14px;
  //     line-height: 22px;
  //   }
  //   .amount {
  //     font-size: 20px;
  //     line-height: 28px;
  //   }
  //   .per {
  //     margin-left: 0.5rem;
  //     font-size: 12px;
  //     line-height: 10px;
  //   }

  //   img {
  //     top: -21px;
  //     left: -16px;
  //     height: 19px;
  //   }

  //   button {
  //     margin-top: 0.5rem;
  //     font-size: 10px;
  //     font-weight: 500;
  //     height: 3rem;
  //   }
  // }

  // .sub-item-wrapper {
  //   .sub-item-icon {
  //     width: 35px;
  //   }
  //   .sub-item-text {
  //     padding-left: 8px;

  //     h4 {
  //       font-size: 14px;
  //       line-height: 18px;
  //     }
  //     p {
  //       font-size: 11px;
  //       line-height: 22px;
  //     }
  //   }
  // }
  // .sub-item-data {
  //   font-size: 12px;
  // }
}

@media only screen and (max-width: 575px) {
  .sub-subtitle {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
    }
  }

  // .sub-plan {
  //   padding-bottom: 2rem;
  //   .plan {
  //     font-size: 11px;
  //     line-height: 14px;
  //   }
  //   .amount {
  //     font-size: 14px;
  //     line-height: 28px;
  //   }
  //   .per {
  //     margin-left: 0;
  //     font-size: 8px;
  //     line-height: 0px;
  //     display: block;
  //   }

  //   img {
  //     top: -21px;
  //     left: -16px;
  //     height: 19px;
  //   }

  //   button {
  //     margin-top: 1.5rem;
  //     font-size: 8px;
  //     font-weight: 500;
  //   }
  // }

  // .sub-item-wrapper {
  //   .sub-item-icon {
  //     width: 35px;
  //   }
  //   .sub-item-text {
  //     padding-left: 8px;

  //     h4 {
  //       font-size: 12px;
  //       line-height: 18px;
  //     }
  //     p {
  //       font-size: 10px;
  //       line-height: 22px;
  //     }
  //   }
  // }
  // .sub-item-data {
  //   font-size: 12px;
  // }
}

// **************************
//  subscription modal design
//***************************
.sub-modal {
  border-radius: 2rem;
  max-width: 885px;
  background-color: #fff;
  border-radius: 2rem;
  max-height: 490px;
  background-color: #fff;
  margin: 0px 40px;
  z-index: 1301 !important;

  .image-block2 {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40%;
    min-width: 38%;

    img {
      margin-left: -100px;
    }
  }
}

.ft-sub-modal-content {
  padding: 6rem 4rem 6rem 0;

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.017em;
    color: $subscription-text-color;
    margin-bottom: 2rem;
    .sub {
      line-height: 145%;
      font-size: 24px;
      display: block;
    }
    .bold {
      line-height: 145%;
      font-size: 29px;
    }
  }
  p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -0.017em;
    font-style: normal;
    font-weight: 400;
    color: #7a8092;
  }
  .confirmation {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -0.017em;
    font-style: normal;
    font-weight: 400;
    color: $subscription-text-color;
  }
}

.action {
  margin-top: 3rem;
  display: flex;
  gap: 20px;

  .modal-primary-button {
    border: none;
    background-image: linear-gradient(
      90deg,
      #203eca,
      #2542ef 10%,
      #4b69f7 75%,
      #7190fe
    );
    background-image: -webkit-linear-gradient(
      90deg,
      #203eca,
      #2542ef 0,
      #4b69f7 37%,
      #7190fe
    );
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    // width: 276px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
    color: #fff;
    transition: all 0.2s;
    padding: 0 1rem;

    &:hover {
      box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
    }
  }

  .modal-secondary-button {
    color: #3353ef;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    background: none;
    border: none;
  }
}

@media only screen and (max-width: 800px) {
  .ft-sub-modal-content {
    // padding: 8rem;

    h3 {
      .sub {
        line-height: 145%;
        font-size: 18px;
        display: block;
      }
      .bold {
        line-height: 145%;
        font-size: 22px;
      }
    }
    p {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.017em;
      font-style: normal;
      font-weight: 400;
      color: #7a8092;
    }
    .confirmation {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.017em;
      font-style: normal;
      font-weight: 400;
      color: $subscription-text-color;
    }
  }

  .image-block2 {
    // display: none !important;
    // max-width: 100px;
  }
  .image-block2 img {
    height: 210px !important;
    margin-left: 20px !important;
  }
}
@media only screen and (max-width: 505px) {
  .image-block2 img {
    height: 160px !important;
    margin-left: 0px !important;
  }
}
.modal-primary-button-wrap {
  min-width: 65%;
}
@media only screen and (max-width: 430px) {
  .bg-image-modal {
    background-size: auto !important;
  }
  .sub-modal {
    margin: 0px 0px !important;
  }
  .image-block2 img {
    height: 100px !important;
    margin-left: 10px !important;
  }
  .image-block3 img {
    height: 130px !important;
  }
  .modal-primary-button-wrap {
    min-width: 20% !important;
    margin-right: 15px !important;
  }
  .sub-modal-content {
    padding-right: 5rem !important;
  }
}
@media only screen and (max-width: 484px) {
  .ft-sub-modal-content p {
    // padding: 6rem 4rem 6rem 0;
    font-size: 15px !important;
  }
  .ft-sub-modal-content h3 {
    // padding: 6rem 4rem 6rem 0;
    font-size: 15px !important;
  }
  .ft-sub-modal-content span {
    // padding: 6rem 4rem 6rem 0;
    font-size: 15px !important;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1050px) {
  .bg-image-modal {
    background-size: contain !important;
  }
  // .cover {
  //   background-size: auto !important;
  // }
}

.marginTop {
  margin-top: 6px !important;
}
