// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 800px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&family=Roboto+Slab&display=swap");

// @import "~bootstrap/scss/bootstrap";
@import "./variables.module.scss";
@import "./component_styles/datepicker.module.scss";
@import "./component_styles/BuldOrderDiscount.module.scss";

@import "./component_styles/Sidebar.module.scss";

@import "./component_styles/NewDiscount.module.scss";

@import "./component_styles/StorewideSale/StorewideSale.module.scss";
@import "./component_styles/StorewideSale/DiscountSummary.module.scss";
@import "./component_styles/StorewideSale/DiscountSettings.module.scss";

@import "./component_styles/AddedDiscounts/AddedDiscounts.module.scss";

@import "./component_styles/StorefrontDesigns/StorefrontDesigns.module.scss";
@import "./component_styles/StorefrontDesigns/Settings.module.scss";
@import "./component_styles/StorefrontDesigns/Preview.module.scss";
@import "./component_styles/SingupForms/SignupForms.module.scss";
@import "./component_styles/SingupForms/SubmissionForms.module.scss";

@import "./component_styles/BulkPrice/BulkPricePopup.module.scss";
@import "./component_styles/BulkPrice/BulkSetting.module.scss";
@import "./component_styles/BulkPrice/BulkPriceLog.module.scss";
@import "./component_styles/Analytics.module.scss";

@import "./component_styles/BulkPrice/BulkPricePagination.module.scss";
@import "./component_styles/CreateASale/CreateASaleSetting.module.scss";
@import "./component_styles/StorefrontDesigns/StorefrontDesignSummary.module.scss";
@import "./component_styles/BulkPrice/BulkViewListTable.module.scss";

@import "./component_styles/Subscription.module.scss";
@import "./component_styles/ModalFreeTrial/ModalFreeTrial.module.scss";
html {
  /* Load system fonts */
  // font-family: -apple-system, BlinkMacSystemFont, San Francisco, Segoe UI,
  //   Roboto, Helvetica Neue, sans-serif;

  /* Make type rendering look crisper */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  /* Deactivate auto-enlargement of small text in Safari */
  text-size-adjust: 100%;

  /* Enable kerning and optional ligatures */
  text-rendering: optimizeLegibility;

  height: 100%;
  width: 100%;
}

@media (max-width: 96em) {
  html {
    font-size: 10px;
  }
}
@media (max-width: 575px) {
  html {
    font-size: 8px;
  }
}

* {
  -webkit-padding-start: 0px;
}

#__next {
  height: 100%;
}
#__next > *:first-child {
  height: 100%;
}
#freshworks-container #launcher-frame {
  max-width: 180px !important;
  height: 65px !important;
  z-index: 518 !important;
  /*  left: 22px !important;
  right: 0px !important;*/
}
img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
/*#freshworks-frame-wrapper{
  left: 30px !important;
  right: 0px !important;
}*/
.background-div {
  height: 100%;
  -moz-transform: scale(0.75);
  -moz-transform-origin: 0 0;
}
.mozilla-width {
  width: 133%;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}
select {
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  padding: 5px;
}
#tidio-chat {
  zoom: 130%;
}
body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  zoom: 75%;
  // -moz-transform: scale(0.95);
  // follwoing lines are out for testing
  // background: linear-gradient(to top left, #bfd2fb, rgba(255, 153, 150, 0), white),
  // linear-gradient(to top right, #c5d5f8, rgba(0, 0, 0, 0), #dcebfa) #d1ddf8;
  // background-repeat:no-repeat;
  // background-attachment: fixed;

  overflow-x: hidden;

  background-color: #f9fafe !important;
}

p {
  margin: 0 !important;
  font-family: "Poppins", sans-serif;
}
// # {
//   z-index: 99 !important;
// }
// #tidio-chat-iframe{

//   display: block;
//   border: none;
//   position: fixed;
//   inset: auto 9px 35px auto;
//   min-width: 252px !important;
//   min-height: 165px !important;
//   width: 60em !important;
//   height: 94px;
//   opacity: 1;
//   margin: 0px;
//   max-height: 100vh;
//   max-width: 100vw;
//   transform: translateY(0px);
//   visibility: visible;
//   border-radius: 47px 30px 47px 47px;
//   background: none transparent !important;
//   transition: none 0s ease 0s !important;
//   z-index: 999999999 !important;
// }
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='gray'/></g></svg>")
    no-repeat;
  background-size: 14px;
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;
  padding-right: 30px !important;
  overflow: hidden;
  // background-color: #efefef;
}
option {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.main {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-top: 3rem;
  padding-bottom: 80px;
}
/*.bg-img{
  background: url('/img/background.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%;
  //height: 100%;
}*/
.no-log-img {
  max-height: 313px;
  max-width: 350px;
  width: auto;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
  // position: absolute; /*Can also be `fixed`*/
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  // margin: auto;
}
.center-in-div {
  position: relative;
  float: left;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text-spinner-blue {
  color: #4765f0 !important;
}
@media only screen and (max-height: 650px) {
  // .no-log-img {
  //   margin-top: 275px;
  // }
}
.edit-discount-action .Polaris-Icon {
  width: 25px;
  height: 20px;
  margin-top: 3px;
}
.delete-discount-action .Polaris-Icon {
  width: 25px;
  height: 25px;
  //margin-top: 3px;
}

body {
  background: url("/img/background.png");
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: 100%;
  background-repeat: repeat-y;
  background-position-y: 115px;
  //height: 100%;
}
.panel-shadow-border {
  background-color: rgb(255, 255, 255);
  -webkit-box-shadow: rgba(31, 72, 191, 0.15) 0px 20px 35px -5px;
  box-shadow: rgba(31, 72, 191, 0.15) 0px 20px 35px -5px;
  outline: 1px #e7ebfe;
  border-radius: 8px !important;
}
.invalid-feedback {
  font-size: 14px !important;
  display: block;
}

.back-btn {
  height: 20px;
  width: 20px;
}

.back-btn:hover {
  cursor: pointer;
}

h3 {
  color: $general-font-color;
  font-weight: 500;
  font-size: 1.5rem;
}

.form-control input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

@media only screen and (max-width: 480px) {
  .mobile-hide {
    display: none !important;
  }
}

//1680
@media only screen and (min-width: 1680px) {
  body {
    zoom: 100%;
  }
}
@media only screen and (min-width: 938px) and (max-width: 1028px) {
  #actionsLabel {
    margin-left: 1rem !important;
  }
}
@media only screen and (min-width: 1029px) and (max-width: 1275px) {
  #actionsLabel {
    margin-left: -0.2rem !important;
  }
}
@media only screen and (min-width: 1248px) and (max-width: 1304px) {
  #actionsLabel {
    margin-left: -1.9rem !important;
  }
}
@media only screen and (min-width: 1304px) and (max-width: 1679px) {
  #actionsLabel {
    margin-left: -2rem !important;
  }
}

@media only screen and (min-width: 1544px) and (max-width: 1680px) {
  #actionsLabel {
    margin-left: -4rem !important;
  }
}
@media only screen and (min-width: 1680px) and (max-width: 1903px) {
  #actionsLabel {
    margin-left: -1.6rem !important;
  }
  #action-bulk {
    margin-left: -1.4rem !important;
  }
}
@media only screen and (min-width: 1904px) and (max-width: 2274px) {
  #actionsLabel {
    margin-left: -3.6rem !important;
  }
  #action-bulk {
    margin-left: -1.4rem !important;
  }
}
@media only screen and (min-width: 2275px) {
  #actionsLabel {
    margin-left: -5.6rem !important;
  }
  #action-bulk {
    margin-left: -2.4rem !important;
  }
}

.pointer {
  cursor: pointer;
}

.v-align-center {
  display: flex;
  align-items: center;
}

.form-control {
  outline: none !important;
}

.form-control:focus {
  border-color: transparent !important;
}

.impacted-text {
  font-weight: 600;
}

.currency-style {
  font-size: 14px;
  position: absolute;
  z-index: 1;
  left: 3px;
  opacity: 0.5;
  width: 25px;
  display: flex;
  justify-content: center;
  // min-width:20px;
  // height: auto;
}

@media only screen and (max-width: 1400px) {
  .currency-style {
    font-size: 14px;
  }
}
@media only screen and (max-width: 430px) {
  .Polaris-Modal-Dialog__Container {
    z-index: 999999999999999 !important;
  }
}
.Polaris-Modal-Dialog__Container {
  -moz-transform: scale(0.75);
}
//
@media only screen and (max-width: 575px) {
  .currency-style {
    font-size: 11px;
  }
}
.SnackbarItem-wrappedRoot {
  zoom: 140%;
}
.SnackbarItem-wrappedRoot .MuiSvgIcon-root {
  fill: #ffff !important;
}
.SnackbarItem-wrappedRoot .SnackbarItem-message {
  font-size: 11px !important;
}

.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-content {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  position: absolute;
  top: 40%;
}
.error-text {
  font-size: 16px;
  font-family: serif;
  font-weight: 500;
  text-align: center;
}
.customSkelton {
  --base-color: #e2e7fa;
  --highlight-color: #d2dbfc;
  // --base-color: #ebebeb;
  // --highlight-color: #f5f5f5;
  --animation-duration: 1.5s;
  --animation-direction: normal;
  --pseudo-element-display: block;
  background-color: var(--base-color);
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  display: inline-flex;
  line-height: 1;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.customSkelton::after {
  content: " ";
  display: var(--pseudo-element-display);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
    90deg,
    var(--base-color),
    var(--highlight-color),
    var(--base-color)
  );
  transform: translateX(-100%);
  animation-name: react-loading-skeleton;
  animation-direction: var(--animation-direction);
  animation-duration: var(--animation-duration);
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}
.block-display {
  display: block;
}
@media only screen and (min-width: 600px) {
  #failure .SnackbarContent-root {
    min-width: 260px !important;
  }
}
.app-embed-modal-body .Polaris-Button.Polaris-Button--primary {
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );
  color: #fff !important;
  border: none;
  border-radius: 4px !important;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 6px 0px !important;
  border-radius: 5px;
  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
  transition: all 0.2s;
  width: 50%;
}
.app-embed-modal-body .Polaris-Button.Polaris-Button--primary:hover {
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
}
.app-embed-modal-body .app-embed-modal-body .Polaris-Button--disabled:hover {
  -webkit-box-shadow: 0 5px 15px hsla(0, 0%, 51.4%, 0.3) !important;
  -moz-box-shadow: 0 5px 15px hsla(0, 0%, 51.4%, 0.3) !important;
  box-shadow: 0 5px 15px hsla(0, 0%, 51.4%, 0.3) !important;
}
.app-embed-modal-body .Polaris-Button--disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

.app-embed-modal-body.disable .Polaris-Button.Polaris-Button--primary {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(182, 181, 181, 0.396)),
    color-stop(23.96%, hsla(0, 0%, 60.8%, 0.462)),
    color-stop(73.96%, hsla(0, 0%, 51.4%, 0.528)),
    color-stop(89.58%, rgba(118, 117, 117, 0.561))
  ) !important;
  background: -webkit-linear-gradient(
    top,
    rgba(182, 181, 181, 0.396),
    hsla(0, 0%, 60.8%, 0.462) 23.96%,
    hsla(0, 0%, 51.4%, 0.528) 73.96%,
    rgba(118, 117, 117, 0.561) 89.58%
  ) !important;
  background: -moz-linear-gradient(
    top,
    rgba(182, 181, 181, 0.396) 0,
    hsla(0, 0%, 60.8%, 0.462) 23.96%,
    hsla(0, 0%, 51.4%, 0.528) 73.96%,
    rgba(118, 117, 117, 0.561) 89.58%
  ) !important;
  background: linear-gradient(
    180deg,
    rgba(182, 181, 181, 0.396),
    hsla(0, 0%, 60.8%, 0.462) 23.96%,
    hsla(0, 0%, 51.4%, 0.528) 73.96%,
    rgba(118, 117, 117, 0.561) 89.58%
  ) !important;
  -webkit-box-shadow: 0 5px 15px hsla(0, 0%, 51.4%, 0.3) !important;
  -moz-box-shadow: 0 5px 15px hsla(0, 0%, 51.4%, 0.3) !important;
  box-shadow: 0 5px 15px hsla(0, 0%, 51.4%, 0.3) !important;
  border-color: lightgrey !important;
}
.close-icon:hover {
  cursor: pointer;
}
.close-icon {
  // position: absolute;
  // right: 30px;
  // top: 32.5px;
  height: 20px;
  margin-top: 4px;
}
.app-embed-modal-body .Polaris-Button.Polaris-Button--outline {
  border: 1px solid #4765f5 !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 10px !important;
  padding: 10px !important;
  margin-top: 10px;
  background-color: transparent !important;
  -webkit-box-shadow: 0 15px 29px -8px rgba(54, 85, 238, 0.1) !important;
  -moz-box-shadow: 0 15px 29px -8px rgba(54, 85, 238, 0.1) !important;
  box-shadow: 0 15px 29px -8px rgba(54, 85, 238, 0.1) !important;
}
.app-embed-modal-body .Polaris-Button.Polaris-Button--outline:hover {
  -webkit-transform: none;
  -moz-transform: none;
  transform: none;
}
.app-embed-modal-body
  .Polaris-Button.Polaris-Button--outline
  .Polaris-Button__Content
  .Polaris-Button__Text {
  font-weight: 800;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-transform: capitalize;
  color: #4765f5;
}
.app-embed-modal-body .contentWrapper {
  display: flex;
  width: 100%;
  margin-bottom: 18px;
  justify-content: center;
}
@media only screen and (max-width: 380px) {
  .app-embed-modal-body {
    // padding-bottom: 8rem !important;
  }
}
@media only screen and (max-width: 300px) {
  .app-embed-modal-body .contentWrapper .title {
    margin-right: 10px !important;
  }
}

.app-embed-modal-body .description {
  display: flex;
  flex-direction: column;
  width: 48%;
  justify-content: space-between;
}
.app-embed-modal-body .Polaris-Scrollable--verticalHasScrolling {
  overflow-y: auto !important;
}
// #custom-scroll {
//   overflow-y: scroll!important;
//   height: 120%;
// }
// #custom-scroll .cont{
//   height: 150%;
// }

.app-embed-modal-body .imageSample {
  border: 1px solid #c1ccff;
  box-sizing: border-box;
  border-radius: 10px;
  margin-left: 18px;
  width: 48%;
}

.cont::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.cont::-webkit-scrollbar-track {
  background: #ffff;
}

/* Handle */
.cont::-webkit-scrollbar-thumb {
  background: #6b78b5;
}

/* Handle on hover */
.cont::-webkit-scrollbar-thumb:hover {
  background: #485fcf;
}
@media only screen and (max-width: 600px) {
  .app-embed-modal-body .imageSample {
    width: 65% !important;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .app-embed-modal-body .description {
    width: 98% !important;
  }
  .app-embed-modal-body .Polaris-Button.Polaris-Button--outline {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.awaiting-sync {
  padding: 0.5rem 0.5rem !important;
  min-height: 3.6rem !important;
}
#app-install-modal-container {
  z-index: 300 !important;
}
.margin-img {
  margin-top: 620px;
}
@media only screen and (max-width: 375px) and (max-height: 548px) {
  .app-embed-modal-body {
    padding-bottom: 10rem !important;
  }
}
div[role="presentation"] {
  z-index: 99999999999 !important;
}
.overlay {
  background: rgba(255,255,255, .75);
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.5;
  // z-index: 999999 !important;

}
.price-content {
  // text-align: center;
  opacity: 0;
  // width: 100%;
  // height: 100%;
  position: relative;
  -webkit-transition: all 0.3s ease - in -out;
  -moz-transition: all 0.3s ease - in -out;
  -o-transition: all 0.3s ease - in -out;
  -ms-transition: all 0.3s ease - in -out;
  transition: all 0.3s ease - in -out;
  opacity: 1;
}
@media only screen and (max-width: 450px) {
  .setPriceCSVWrap{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.setPriceCSV {
  display: flex !important;
}