/*  Submission Form CSS */
@import "../../variables.module.scss";
.submission-form-grid {
  .grid-value-container {
    .form-link {
      a {
        font-size: 12px !important;
      }
    }
  }
  .submitted-data {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 12px !important;
    }
    strong {
      margin-right: 5px !important;
    }
  }

  .action-icon-wrapper {
    padding-right: 0 !important;

    span {
      font-size: 12px !important;
    }
    span:first-child {
      margin-right: 10px !important;
      color: $icon-active-color;
      font-size: 12px !important;
    }
  }

  .submission-form-collapse {
    width: 100% !important;
  }

  .more-info-wrapper {
    max-width: 100%;
    width: 100%;
    float: right;
  }
  .more-info-container {
    display: flex;
    justify-content: space-between;
  }
}

.signup-form-submitted-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.signup-form-submitted-number {
  white-space: nowrap;
}

// @media only screen and (min-width: 768px) {
//   .action-icon-wrapper {
//     span:first-child {
//       font-size: 10px !important;
//     }
//   }
// }
