@import "../../variables.module.scss";
.save-discount-btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );

  font-size: 1.3rem;
  font-weight: 600;
  height: 3.5rem;
  border-radius: 5px;

  -webkit-box-shadow: 0px 20px 20px -10px rgba(35, 43, 99, 0.4);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.4);
  transition: transform 0.1s;
}

.save-discount-btn:hover {
  //transform: scale(0.98);
  -webkit-box-shadow: 0px 20px 20px -10px rgba(35, 43, 99, 0.3);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.3);
}

.hr-discount-settings-title {
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  border-top: 3px solid #c3d2f6;
  // border-bottom: 1.5px solid #e6ebf3;
  // border-bottom: 3px solid #d4ddf1;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(169, 164, 248, 0.4);
  -moz-box-shadow: 0px 2px 10px 0px rgba(169, 164, 248, 0.4);
  box-shadow: 0px 5px 10px 0px rgba(169, 164, 248, 0.4);

  // margin-right: 6rem;
}

.discount-settings {
  margin-left: 2.5rem;
  margin-right: 1rem;
}

.save-discount-btn-div {
  width: 25%;
  margin-bottom: 0.6rem;
  // height: auto;
}

.storewide-sale-card {
  // padding: 15px;
  padding: 20px 25px;
  border-radius: 5px;
  // margin-right: 6rem;
  // margin-left: 6rem;
  background-color: white;
  // background-color:rgba(255, 255, 255, 0.6);
  border-color: white;
  border-width: 0.25rem;
  // -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);

  .text--muted {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .text--dull {
    color: rgb(151, 149, 149);
  }

  .label-nowrap {
    white-space: nowrap;
  }

  input {
    height: 3.4rem;
    font-size: 1.3rem;
    font-weight: 400;
    color: black;
  }
}

#discount-type-tab,
#minimum-requirements-tab {
  a {
    font-size: 1.3rem;
    font-weight: 400;
  }
  a.nav-link {
    color: $icon-inactive-color;
  }
  a.active {
    color: $icon-active-color;
  }
  a:hover {
    cursor: pointer;
  }
}

.discount-type-tab-contents {
  label {
    color: black;
    font-size: 1.5rem;
    font-weight: 400;
  }
  .input--group {
    width: 50% !important;
  }
  input {
    height: 3.4rem;
    font-size: 1.3rem;
    font-weight: 400;
    color: black;
  }
  .input-group--text {
    background-color: white;

    font-size: 1.6rem;
  }
  .input-group--append {
    border-right: none !important;
    font-weight: 400;
  }
  .input-group-text--append {
    border-left: none !important;
  }
  .input-group--prepend {
    border-left: none !important;
  }
  .input-group-text--prepend {
    border-right: none !important;
  }

  /* BUY X GET Y   */

  .select-product {
    height: 30px !important;
  }

  .form--check {
    padding-left: 25px !important;
    .custom-radio-btn {
      margin-left: -25px !important;
      height: 18px !important;
      width: 18px !important;
    }
  }
  .item-select {
    height: 36px !important;
    font-size: 12px !important;
  }
  hr {
    margin: 0 -25px !important;
  }
  .border-red {
    border: 1px solid rgb(220, 53, 69);
  }

  .ml-25 {
    margin-left: 25px !important;
  }

  .browse-btn {
    background-color: #4765f0 !important ;
    border: none !important;
    padding: 9px 5px !important;
  }

  // selected product or collection image
  .img-container {
    width: 4rem !important;
    height: 4rem !important;
    display: block;
    background-color: white;
    border: 1px solid #e1e3e5;
    img {
      max-width: 100%;
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
}

.stackable {
  label {
    color: black;
    font-size: 1.5rem;
    font-weight: 400;
  }
  input {
    height: 3.4rem;
    font-size: 1.3rem;
    color: black;
  }
}

.position-sticky {
  background-color: #f6f6f6;
}

@media only screen and (max-width: 991px) {
  .discount-settings {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .storewide-sale-card .text--muted {
    font-weight: 300;
  }

  .storewide-sale-card {
    input {
      font-weight: 300;
    }
  }

  .discount-type-tab-contents {
    .input-group--append {
      font-weight: 300;
    }
  }
}

@media only screen and (max-width: 575px) {
  .discount-settings {
    padding-bottom: 15rem;
  }
}
