$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 800px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);
$icon-active-color: #4765f0;
$icon-hover-color: #4765f0;
$icon-inactive-color: #a0b6e4;
$general-font-color: #081e57;
$outline-btn-color: #4454c7;

$tab-heading-color: #6c84b7;
$table-heading-color: #003d87;
$table-row-border-color: #dfe6f3;

$subscription-text-color: #001556;
