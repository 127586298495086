@import "../../variables.module.scss";
.box {
  width: 100%;
  margin: 0 auto;
  max-height: 70vh;
  margin-top: calc(25vh - 20vh - 5px);
  margin-bottom: calc(25vh - 20vh - 5px);

  background: #fff;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid lightgray;
  overflow-y: auto;
}

.viewlist-style {
  font-size: medium;
}

.view-list-map {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
