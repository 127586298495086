@import "../../variables.module.scss";
.discount-labels {
  padding-bottom: 2rem;

  .discount-label-status--left {
    margin-left: 8rem;
  }
  .discount-label-discount--left {
    margin-left: 10rem;
  }

  .discount-label-discount--sec {
    margin-left: 2rem;
  }

  .discount-label-discount--left-created {
    margin-left: 0.5rem;
  }

  .discount-label-discount--left-sku {
    margin-left: -1.5rem;
  }

  .discount-label-users--left {
    margin-left: -1rem;
  }
  h4 {
    color: $general-font-color;
    font-weight: bold;
  }
}

.log-heading {
  font-size: 1.3rem;
  font-weight: 600;
  color: #081e57;
}

.current-discount-card {
  // border-radius: 5px;
  // padding: 5px 25px;
  // padding-top: 20px;
  border-radius: 5px;
  padding: 5px 30px;
  height: 7.5rem;
  display: flex;
  justify-content: center;

  background-color: white;
  border-color: white;
  border-width: 0.25rem;

  -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);

  .action-icon:hover {
    cursor: pointer;
  }

  .hidden-label {
    display: none;
    h4 {
      color: $general-font-color;
      font-weight: bold;
    }
  }

  h4 {
    color: $icon-active-color;
    font-weight: bold;
  }
  span {
    font-size: 1.5rem;
  }
  .current-discount-status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
    font-size: 1.3rem;
    border-radius: 5px;
  }
  .current-discount-status--active {
    padding: 0.5rem 2.5rem;

    background-color: #c6f4be;
    color: #356d1b;
  }
  .current-discount-status--expired {
    background-color: #facbc1;
    color: #762b11;
  }
  .current-discount-status--inactive {
    background-color: #e1eaf2;
    color: #5f7690;
  }
  svg:hover {
    cursor: pointer;
  }

  /* toggle button start */
  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 26px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    // Position when unchecked
    position: absolute;
    content: "";
    height: 18px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-image: linear-gradient(
      90deg,
      #203eca,
      #2542ef 10%,
      #4b69f7 75%,
      #7190fe
    );
    background-image: -webkit-linear-gradient(
      90deg,
      #203eca,
      #2542ef 0,
      #4b69f7 37%,
      #7190fe
    );
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #4765f0;
  }

  input:checked + .slider:before {
    // Slider Initial Position when checked
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  /* toggle button end */
}
.current-discount-card:hover {
  cursor: pointer;
  // justify-content: space-around;
}

@media only screen and (min-width: 575px) and (max-width: 1200px) {
  .discount-label-discount--left {
    margin-left: 7rem !important;
  }
}

// @media only screen and (max-width: 768px) {
//   .discount-labels {
//     display: none;
//     padding-bottom: 2rem;
//     .discount-label-status--left {
//       margin-left: 13rem;
//     }
//     .discount-label-discount--left {
//       margin-left: 4rem;
//     }

//     .discount-label-discount--right {
//       margin-right: 4rem;
//     }
//     .discount-label-users--left {
//       margin-left: -1rem;
//     }
//     h4 {
//       color: $general-font-color;
//       font-weight: bold;
//     }
//   }
//   .current-discount-card {
//     .hidden-label {
//       display: block;
//     //   h3 {
//     //     color: $general-font-color;
//     // font-weight: 600;
//     // font-size: 16px;
//     //   }
//     }

//     h4 {
//       color: $icon-active-color;
//       font-weight: bold;
//       font-size: 2rem !important;
//     }
//     span {
//       font-size: 1.8rem;
//     }
//   }
// }

// @media only screen and (max-width: 425px) {
//   .current-discount-card {
//     .current-discount-status {
//       font-size: 1.5rem;
//       padding-top: 3px;
//       padding-bottom: 0px;
//       padding-right: 10px;
//       padding-left: 10px;
//       height: 30px;

//       // width: 9rem;
//       border-radius: 5px;
//     }
//   }
// }

// @media only screen and (max-width: 375px) {
//   .current-discount-card {
//     .current-discount-status {
//       font-size: 1.5rem;
//       padding-top: 1px;
//       padding-bottom: 0px;
//       padding-right: 5px;
//       padding-left: 5px;
//       height: 25px;

//       // width: 9rem;
//       border-radius: 5px;
//     }
//   }
// }

// @media only screen and (max-width: 320px) {
//   .current-discount-card {
//     .current-discount-status {
//       margin-top: 15px;
//       font-size: 1.5rem;
//       padding-top: 3px;
//       padding-bottom: 0px;
//       padding-right: 10px;
//       padding-left: 10px;

//       // width: 9rem;
//       border-radius: 5px;
//     }
//     .switch {
//       margin-top: 10px;
//     }
//   }
// }
