@import "../variables.module.scss";
.main-added-discount {
  margin-left: 25rem;
  margin-right: 8rem;
  margin-top: 3rem;
}

.title,
.subtitle {
  color: $general-font-color;
}

.title {
  font-size: 2.5rem;
}

.app-name-title {
  font-size: 2.8rem;
  font-weight: 700;
  cursor: pointer;
}

.subtitle {
  font-size: 2rem;
}

.sub-headings {
  font-size: 2.2rem;
  color: $general-font-color;
  font-weight: 500;
}

.subtitle--purple {
  font-weight: bold;
  color: $icon-active-color;
}

.hr-title-homepage {
  color: #d9e3fa;
  height: 3px !important;
  opacity: 0.25;
  background-color: currentColor;
}

.card-discount--col {
  height: 40rem; //distance between card rows
}

// .card-row {
//   width: 80%;
//   margin-right: 0rem;
// }

.card-discount {
  border-radius: 6px;
  padding-top: 5rem;
  text-align: center;
  align-items: center;
  align-content: center;
  height: 35rem; //card height
  width: 90%;
  // background-color:rgba(255, 255, 255, 0.6);
  background-color: white;
  border-color: white;

  transition: transform 0.1s;
  border-width: 0.25rem;

  -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.4);
  -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.4);
  box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.4);
}

.card-discount:hover {
  cursor: pointer;
  //transform: scale(0.99);
  -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.3);
  -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.3);
  box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.3);
}

.tile-icon-1 {
  width: 96px;
  height: auto;
}

.tile-icon-2 {
  width: 90px;
  height: auto;
}

.tile-icon-3 {
  width: 60px;
  height: auto;
}

.tile-icon-4 {
  width: 80px;
  height: auto;
  margin-bottom: 2rem;
}

.tile-icon-5 {
  width: 80px;
  height: auto;
  margin-bottom: 1rem;
}

.tile-icon-6 {
  width: 85px;
  height: auto;
}

.homescreen-coming-soon {
  background-color: grey;
}

.card--title {
  margin-top: 3rem;
  margin-bottom: 2rem;
  color: $icon-active-color;
  font-size: 2.1rem;
  font-weight: 600;
}

.card--text {
  line-height: 25px;
  font-size: 1.4rem;
  padding: 0 30px;
  font-weight: 400;
}

.card--body-4 {
  padding: 0 12px;
}

.modal-saving-container-newdiscount {
  height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-heading-newdiscount {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 5rem;
}

.modal-text-newdiscount {
  font-size: 1.3rem;
  text-align: center;
  padding-right: 5rem;
  padding-left: 5rem;
  margin-top: 3rem;
}

.cancel-modal-btn-newdiscount {
  margin-top: 3rem;
}

.app-install-modal-bg {
  // background: linear-gradient(to top left, #bfd2fb, rgba(255, 153, 150, 0), white),
  // linear-gradient(to top right, #c5d5f8, rgba(0, 0, 0, 0), #dcebfa) #d1ddf8;
  background-color: #f9fafe !important;
}

.homepage-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.homepage-card {
  border: none;
  border-radius: 1rem;
  background-color: white;
  border-color: white;
  flex: 1;
  display: flex;
  flex-direction: row;
  // height: 30rem;
  // -webkit-box-shadow: 0px 20px 27px -3px rgba(159, 189, 237, 0.66);
  // -moz-box-shadow: 0px 20px 27px -3px rgba(159, 189, 237, 0.66);
  // box-shadow: 0px 20px 27px -3px rgba(159, 189, 237, 0.66);
}

.homepage-container-margin {
  margin-right: 5rem;
}

.homepage-card-left {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homepage-card-right {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-right: 3rem;
}

.homepage-icon {
  // height: 309px;
  width: 100%;
  height: auto;
  // width: auto;
  object-fit: cover;
}
.bottom-container {
  min-height: 300px;
}
.homepage-card-title {
  color: $icon-active-color;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
}

.homepage-card-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 15px;
}

.homepage-card-btn {
  display: flex;
  height: 4rem;
  width: fit-content;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border: $icon-active-color solid 2px;
  border-radius: 10rem;
  color: $icon-active-color;
  font-weight: 600;
  font-size: 1.3rem;
  transition: all 0.2s;
  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
}

.homepage-card-btn:hover {
  //transform: scale(0.98);
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
}
.vh100 {
  height: 100vh;
}
@media only screen and (min-width: 1677px) {
  .vh100 {
    height: 80vh !important;
  }
}
@media only screen and (max-width: 575px) {
  .homepage-card-left {
    width: 100%;
  }

  .homepage-card-right {
    width: 100%;
    padding: 5rem;
  }

  .homepage-card {
    display: flex;
    flex-direction: column;
  }

  .homepage-icon {
    height: 25rem;
    width: 30rem;
    margin-bottom: -3rem;
  }

  .homepage-card-btn {
    width: 50%;
  }
}

@media only screen and (max-width: 991px) {
  .app-name-title {
    font-size: 2.5rem;
    font-weight: 500;
  }

  .homepage-card-title {
    font-weight: 500;
  }

  .homepage-card-text {
    font-weight: 300;
  }

  .homepage-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .homepage-container-margin {
    margin-right: 0rem;
    margin-bottom: 3rem;
  }
}
