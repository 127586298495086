@import "../variables.module.scss";
.topbar-alart {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  white-space: nowrap;

  background-color: rgba(255, 255, 255, 0.4);
  align-items: center;
  border-bottom: solid 3px white;
}

.sidenav {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;

  line-height: 0.5rem;
  display: flex;
  flex-direction: row;
  white-space: nowrap;

  background-color: rgba(255, 255, 255, 0.4);
  align-items: center;

  h4 {
    font-size: 1.4rem;
    font-weight: 600;
  }
}

.sidenav-ham {
  flex-direction: column;
  align-items: flex-start;

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: #003b79;
  }
}

.app-name-navbar {
  margin-left: 2rem;
  margin-top: 0.5rem;
}

.sidenav::-webkit-scrollbar {
  display: none;
}

.sidebar-text {
  font-weight: 600;
  font-size: 1.6rem;
  color: #081e57;
  margin-top: 0.5rem;
}

.sidebar-text-selected {
  font-weight: 600;
  font-size: 1.6rem;
  color: #4765f0;
  margin-top: 0.5rem;
}

.sidebar-text-margin {
  margin-bottom: 2rem;
}

/* The navigation menu links */
.sidenav a {
  // padding: 4px 4px 4px 4px;
  // padding-right: 3rem;
  // padding-left: 3rem;
  text-decoration: none;
  text-align: center;
  font-size: 1.4rem;
  color: $general-font-color;
  display: block;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: $general-font-color;
}

.link-icon {
  width: 30px;
  color: $general-font-color;
}

.link .link-icon--inactive {
  color: $icon-inactive-color;
  transition: transform 0.1s;
  margin-top: 0.6rem;
}

.svg-icon--active {
  margin-top: 0.6rem;
  line-height: 0.5rem;
}

.link:hover {
  .sidebar-text {
    font-weight: 600;
    font-size: 1.4rem;
    color: $icon-hover-color;
    // transform: scale(0.98);
  }

  .svg-icon--inactive {
    fill: $icon-hover-color;
    // transform: scale(0.97);
  }
  .sidenav a {
    color: $general-font-color;
  }
}

.link-icon--inactive:hover {
  .svg-icon--inactive {
    fill: $icon-hover-color;
  }
  .sidenav a {
    color: $general-font-color;
  }
}

.logo {
  height: 6rem;
  width: 6rem;
}

.logo-fetching-modal {
  height: 6rem;
  width: 6rem;
  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.5);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.5);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.5);
}

.link .logo {
  border-radius: 5%;

  -webkit-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.5);
  -moz-box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.5);
  box-shadow: 0px 20px 20px -10px rgba(23, 52, 251, 0.5);
}

.icon-hr {
  width: 7%;
}

.logo-icon-hr {
  width: 70%;
}

.active-hr {
  width: 80%;
  margin-bottom: 1.7rem;
  margin-top: 1.7rem;
  border-top: 0.25rem solid white;
}

@media only screen and (max-width: 991px) {
  .navbar-content {
    display: none;
  }

  .logo {
    height: 4rem;
    width: 4rem;
  }

  .logo-icon-hr {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .hamburger {
    display: none;
  }
}

/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
// @media screen and (max-height: 450px) {
//   .sidenav {
//     padding-top: 15px;
//   }
//   .sidenav a {
//     font-size: 18px;
//   }
// }
// @media only screen and (max-width: 1024px) {
//   .sidenav {
//     width: 10rem; /* Set the width of the sidebar */
//   }
//   .logo {
//     width: 100%;
//     height: auto;
//   }
//   .icon-label {
//     display: none;
//   }
//   .link {
//     padding: 1.5rem;
//     margin-bottom: 1.5rem;
//   }
// }

// @media only screen and (max-width: 390px) {
//   .sidenav {
//     width: 7rem; /* Set the width of the sidebar */
//   }
//   .logo {
//     width: 40px;
//     height: auto;
//     margin-left: 0.3rem;
//   }
//   .link {
//     padding: 0.7rem;
//     margin-bottom: 1.5rem;
//   }

//   .sidebar-text-selected{
//     display: none;
//   }

//   .sidebar-text{
//     display: none;
//   }

//   .svg-icon--inactive{
//     height:25px;
//     width:25px;
//   }

//   .app-name-navbar{
//     display: none;
//   }
// }
