@import "../../variables.module.scss";
.bulk-filter-modal-img {
  height: 40px;
  width: 40px;
  //margin: auto;
  //border: 1px solid rgba(0, 0, 0, 0.1);
  //padding: 3px;
}
.image-loader {
  height: 20px;
  width: 20px;
  align-self: center;
}
.bulk-filter-modal-img-variant {
  height: 40px;
  width: 40px;
  //margin: auto;
  //border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 2px;
}
.Polaris-Scrollable--verticalHasScrolling {
  overflow-y: hidden !important;
}
.view-modal-scrollable::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.contentA {
  height: 65vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin: auto;
}
.contentB {
  margin: auto;
  height: 85vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.contentC {
  height: 54vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin: auto;
}
.contentD {
  height: 65vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin: auto;
}
@media only screen and (min-width: 1677px) {
  .contentA {
    height: 50vh !important;
  }
  .contentB {
    height: 65vh !important;
  }
  .contentC {
    height: 50vh !important;
  }
  .contentD {
    height: 50vh !important;
  }
}

/* Optional: show position indicator in red */
.view-modal-scrollable::-webkit-scrollbar-thumb {
  background: transparent;
}
.bulk-modal-header-title {
  font-size: 1.7rem;
}
.impacted-modal-header-title {
  font-size: 1.7rem;
  margin-left: 10px;
}
.fw-600 {
  font-weight: 600 !important;
}
.bulk-filter-modal-hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.bulk-filter-modal h6 {
  font-size: 1.3rem;
}
.bulk-modal-title {
  margin-bottom: 15px;
}
.impacted-modal-title {
  margin-bottom: 15px;
}
@media only screen and (max-width: 768px) {
  .impacted-modal-title {
    margin-left: 10px;
  }
  .bulk-modal-header-title {
    margin-left: 19px;
  }
  .impacted-modal-header-title {
    margin-left: 0px;
  }
}
.bulk-modal-title span {
  font-size: 1.3rem;
  font-weight: 600;
}
.impacted-modal-title span {
  font-size: 1.3rem;
  font-weight: 600;
}
.text--muted {
  font-size: 1.3rem;
  font-weight: 400;
}
.active-color {
  color: #2b2eff;
}
.color-white {
  color: white !important;
}
.pagination-deactive {
  background-color: white !important;
  background-color: white !important;
  background-image: none !important;
  border: 1px solid lightgrey !important;
}
.pagination-deactive svg {
  fill: black !important;
}
.pagination {
  width: 35px;
  height: 28px;
}
.pagination svg {
  fill: white;
  position: relative;
  left: 3px;
  stroke: white;
}

.check-container .Polaris-Checkbox__Icon svg {
  fill: white !important;
}
.check-container
  .Polaris-Checkbox__Input:checked
  + .Polaris-Checkbox__Backdrop::after,
.Polaris-Checkbox:not(.Polaris-Checkbox--newDesignLanguage)
  .Polaris-Checkbox__Input.Polaris-Checkbox__Input--indeterminate
  + .Polaris-Checkbox__Backdrop::after {
  background: #203eca !important;
}

.aiva-btn {
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );
  border: none;
  font-size: 1.3rem;
  border-radius: 5px;
  -webkit-box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  -moz-box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  transition: transform 0.1s;
}

.bulk-modal-filter-box {
  border: 1px solid #4b69f7;
  font-size: 1.3rem;
  border-radius: 5px;
  background: rgb(240, 240, 253);
  box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  -webkit-box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  -moz-box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);

  padding-left: 8px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  color: #1330ba;
}
.bulk-modal-filter-box:focus {
  border: 1px solid #1330ba;
  outline-color: #1330ba;
  box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  -webkit-box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  -moz-box-shadow: 0px 20px 20px -15px rgba(23, 52, 251, 0.4);
  color: #1330ba;
}
.bulk-modal-search-box input::placeholder {
  /* Most modern browsers support this now. */
  font-size: 1.3rem;
  position: relative;
  left: 8px;
  color: #4b69f7;
}

.bulk-modal-header-div .Polaris-Modal-CloseButton {
  height: 73px;
}
.bulk-modal-row-height {
  height: 40px;
}
.Polaris-Modal-Dialog__Modal {
  max-height: 100% !important;
  height: 100% !important;
}
@media only screen and (max-width: 767px) {
  .bulk-modal-header-div {
    padding: 0 !important;
  }
  .bulk-modal-header-div .Polaris-Modal-CloseButton {
    margin: 0 !important;
  }
}
@media only screen and (min-width: 768px) {
  .bulk-modal-header-div {
    padding: 12px 20px !important;
  }
  .bulk-modal-header-div .Polaris-Modal-CloseButton {
    margin: auto 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .bulk-modal-row-height {
    height: 44px;
  }
}
