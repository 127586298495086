@import "../variables.module.scss";
.subscription-wrapper {
  padding: 6rem 3rem;
}
.sub-title {
}
.modal-primary-button2 {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef,
    #4b69f7 37%,
    #7190fe
  );
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  min-height: 4rem;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 20px 20px -12pxrgba (23, 52, 251, 0.4);
  color: #fff;
  transition: all 0.2s;
  padding: 1rem 1rem;
}
.modal-secondary-button2 {
}
.row2 {
  display: flex;
  /* flex-wrap: wrap; */
  margin-right: -15px;
  margin-left: -15px;
}
.sub-subtitle {
  h2 {
    color: $subscription-text-color;
    font-style: normal;
    font-weight: 600px;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: -0.017em;
  }

  p {
    color: $subscription-text-color;
    font-style: normal;
    font-weight: 400px;
    font-size: 29px;
    line-height: 145%;
    letter-spacing: -0.017em;
  }
}

.sub-plan {
  position: relative;
  padding-bottom: 2rem;
  .plan {
    color: $subscription-text-color;
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    text-transform: uppercase;
  }
  .amount {
    font-size: 40px;
    color: $subscription-text-color;
    text-transform: capitalize;
    line-height: 60px;
    font-weight: 700;
  }
  .per {
    margin-left: 1rem;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-transform: capitalize;
    color: $subscription-text-color;
  }

  img {
    position: absolute;
    top: -40px;
    left: -22px;
    background: #f9fafb;
    padding: 0 15px;
  }

  button {
    border: none;
    background-image: linear-gradient(
      90deg,
      #203eca,
      #2542ef 10%,
      #4b69f7 75%,
      #7190fe
    );
    background-image: -webkit-linear-gradient(
      90deg,
      #203eca,
      #2542ef 0,
      #4b69f7 37%,
      #7190fe
    );
    box-sizing: border-box;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    // height: 4rem;
    // width: 276px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
    color: #fff;
    transition: all 0.2s;
    padding: 1rem 5rem;

    &:hover {
      box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
    }
  }
  button:disabled,
  button[disabled] {
    opacity: 0.4;
  }
}

.sub-item-wrapper {
  display: flex;
  flex-direction: row;

  .sub-item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px 8px 0;
    width: 46px;
  }
  .sub-item-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8px;

    h4 {
      font-family: "Poppins";
      color: $subscription-text-color;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: -0.017em;
    }
    p {
      color: $subscription-text-color;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: -0.017em;
    }
  }
}

.sub-item-data {
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.017em;
  color: #4155f0;
}

@media only screen and (max-width: 1600px) {
  .sub-subtitle {
    h2 {
      font-size: 30px;
      line-height: 50px;
    }

    p {
      font-size: 16px;
    }
  }

  .sub-plan {
    padding-bottom: 2rem;
    .plan {
      font-size: 16px;
      line-height: 26px;
    }
    .amount {
      font-size: 24px;
      line-height: 32px;
    }
    .per {
      margin-left: 0.5rem;
      font-size: 12px;
      line-height: 10px;
    }

    img {
      top: -21px;
      left: -16px;
      height: 19px;
    }

    button {
      margin-top: 0.5rem;
      font-size: 12px;
      font-weight: 500;
      padding: 1rem 3rem;
    }
  }

  .sub-item-wrapper {
    .sub-item-icon {
      width: 40px;
    }
    .sub-item-text {
      padding-left: 8px;

      h4 {
        font-size: 18px;
        line-height: 22px;
      }
      p {
        font-size: 14px;
        line-height: 15px;
      }
    }
  }
  .sub-item-data {
    font-size: 14px;
  }
}

@media only screen and (max-width: 840px) {
  .sub-subtitle {
    h2 {
      font-size: 25px;
      line-height: 50px;
    }

    p {
      font-size: 16px;
    }
  }

  .sub-plan {
    padding-bottom: 2rem;
    .plan {
      font-size: 14px;
      line-height: 22px;
    }
    .amount {
      font-size: 20px;
      line-height: 28px;
    }
    .per {
      margin-left: 0.5rem;
      font-size: 12px;
      line-height: 10px;
    }

    img {
      top: -21px;
      left: -16px;
      height: 19px;
    }

    button {
      margin-top: 0.5rem;
      font-size: 10px;
      font-weight: 500;
      padding: 0.8rem 3rem;
    }
  }

  .sub-item-wrapper {
    .sub-item-icon {
      width: 35px;
    }
    .sub-item-text {
      padding-left: 8px;

      h4 {
        font-size: 14px;
        line-height: 18px;
      }
      p {
        font-size: 11px;
        line-height: 22px;
      }
    }
  }
  .sub-item-data {
    font-size: 12px;
  }
}

@media only screen and (max-width: 784px) and (min-width: 670px) {
  .sub-plan {
    .plan {
    }
    .amount {
    }
    .per {
    }

    button {
      height: 4rem;
    }
  }
}

@media only screen and (max-width: 650px) {
  .sub-subtitle {
    h2 {
      font-size: 20px;
      line-height: 30px;
    }

    p {
      font-size: 14px;
    }
  }

  .sub-plan {
    padding-bottom: 2rem;
    .plan {
      font-size: 11px;
      line-height: 14px;
    }
    .amount {
      font-size: 14px;
      line-height: 28px;
    }
    .per {
      margin-left: 0;
      font-size: 8px;
      line-height: 0px;
      display: block;
    }

    img {
      top: -21px;
      left: -16px;
      height: 19px;
    }

    button {
      margin-top: 1.5rem;
      font-size: 8px;
      font-weight: 500;
      padding: 0.5rem 1rem;
      height: 4rem;
    }
  }

  .sub-item-wrapper {
    .sub-item-icon {
      width: 35px;
    }
    .sub-item-text {
      padding-left: 8px;

      h4 {
        font-size: 12px;
        line-height: 18px;
      }
      p {
        font-size: 10px;
        line-height: 22px;
      }
    }
  }
  .sub-item-data {
    font-size: 12px;
  }
}

// **************************
//  subscription modal design
//***************************
.sub-modal {
  border-radius: 2rem;
  max-width: 885px;
  background-color: #fff;
  border-radius: 2rem;
  height: 510px;
  background-color: #fff;
  margin: 0px 40px;
  z-index: 1301 !important;
  -moz-transform: scale(0.75);

  .image-block {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40%;

    img {
      margin-left: -100px;
    }
  }
}

.center-sub-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sub-modal-content {
  padding-right: 2rem;

  .cross-btn {
    cursor: pointer;
    margin-top: -30px;
  }

  h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.017em;
    color: $subscription-text-color;
    margin-bottom: 2rem;
    .sub {
      line-height: 145%;
      font-size: 24px;
      display: block;
    }
    .bold {
      line-height: 145%;
      font-size: 29px;
    }
  }
  p {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -0.017em;
    font-style: normal;
    font-weight: 400;
    color: #7a8092;
  }
  .confirmation {
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -0.017em;
    font-style: normal;
    font-weight: 400;
    color: $subscription-text-color;
  }
}

.action {
  margin-top: 3rem;
  display: flex;
  gap: 20px;

  .modal-primary-button {
    border: none;
    background-image: linear-gradient(
      90deg,
      #203eca,
      #2542ef 10%,
      #4b69f7 75%,
      #7190fe
    );
    background-image: -webkit-linear-gradient(
      90deg,
      #203eca,
      #2542ef 0,
      #4b69f7 37%,
      #7190fe
    );
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 500;
    height: 4rem;
    // width: 276px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);
    color: #fff;
    transition: all 0.2s;
    padding: 0 1rem;

    &:hover {
      box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
    }
  }

  .modal-secondary-button {
    color: #3353ef;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    background: none;
    border: none;
  }
}

@media only screen and (min-width: 801px) {
  .sub-modal {
    height: 510px;
  }
}
@media only screen and (max-width: 380px) {
  .sub-modal {
    min-height: 500px;
  }
}

@media only screen and (max-width: 800px) {
  .sub-modal {
    height: 480px;
  }

  .image-block {
    img {
      margin-left: -60px;
      height: 276px;
    }
  }
  .sub-modal-content {
    h3 {
      .sub {
        line-height: 145%;
        font-size: 18px;
        display: block;
      }
      .bold {
        line-height: 145%;
        font-size: 22px;
      }
    }
    p {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.017em;
      font-style: normal;
      font-weight: 400;
      color: #7a8092;
    }
    .confirmation {
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.017em;
      font-style: normal;
      font-weight: 400;
      color: $subscription-text-color;
    }
  }

  .image-block {
    // display: none !important;
    // max-width: 100px;
  }
  .image-block img {
    height: 350px !important;
    // margin-left: 20px !important;
  }
}

@media only screen and (max-width: 649px) and (min-width: 560px) {
  .sub-modal-content {
    padding-left: 4rem;
  }

  .image-block2 img {
    height: 200px !important;
    margin-left: -4px !important;
  }
}

@media only screen and (max-width: 650px) {
  .sub-modal {
    height: 480px;
  }
  .sub-modal-content {
    // padding: 8rem;

    .cross-btn {
      margin-top: -20px;
    }

    h3 {
      .sub {
        line-height: 145%;
        font-size: 14px;
      }
      .bold {
        line-height: 145%;
        font-size: 15px;
      }
    }
    p {
      font-size: 10px;
      line-height: 18px;
      letter-spacing: -0.017em;
      font-style: normal;
      font-weight: 400;
      color: #7a8092;
    }
    .confirmation {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: -0.017em;
      font-style: normal;
      font-weight: 400;
      color: $subscription-text-color;
    }
    .action {
      .modal-primary-button2 {
        font-size: 10px;
        padding: 0.8rem 1.4rem;
      }
      .modal-secondary-button {
        font-size: 10px;
      }
    }
  }
}
@media only screen and (max-width: 505px) {
  .image-block img {
    height: 255px !important;
    margin-left: 0px !important;
  }
}
@media only screen and (max-width: 430px) {
  .bg-image-modal {
    background-size: contain !important;
  }
  .sub-modal {
    margin: 0px 0px !important;
  }
  .image-block img {
    height: 135px !important;
    margin-left: 0px !important;
  }
  .modal-primary-button2 {
    margin-right: 10px;
  }
  .sub-modal-content {
    padding-right: 5rem !important;
  }
}
@media only screen and (min-width: 431px) and (max-width: 1050px) {
  .bg-image-modal {
    background-size: contain !important;
  }
}

#modal-subscriptions {
  z-index: 99999999999 !important;
}
.Polaris-Modal-Dialog__Container {
  z-index: 99999999999 !important;
}
@media only screen and (min-width: 845px) {
  .cont {
    max-height: 500px !important;
    max-width: 620px !important;
  }
}
