@import "../../variables.module.scss";
#storewide-sale {
  margin-top: 3rem;
  //min-height: calc(100% - 111px) ;
}

// @media only screen and (max-width: 1400px) {
//   #storewide-sale {
//     margin-left: 24rem !important;
//   }
// }
// @media only screen and (max-width: 1024px) {
//   #storewide-sale {
//     margin-left: 15rem !important;
//     margin-right: 1rem;
//     margin-top: 4rem;
//   }
// }

// @media only screen and (max-width: 425px) {
//   #storewide-sale {
//     margin-left: 13rem !important;
//     margin-right: 1rem;
//     margin-top: 4rem;
//   }
//   .discount-setting-col,
//   .discount-summary-col {
//     padding: 0 !important;
//   }
// }
