@import "../variables.module.scss";
.picker-label,
.picker-label > div.react-datepicker-wrapper,
.picker-label
  > div
  > div.react-datepicker__input-container
  .picker-label
  > div
  > div.react-datepicker__input-container
  input {
  width: 100%;
}

.bevy-date-wrapper {
  height: 50px !important;
}

.react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker__input-container * input {
  width: 100% !important;
}
.picker-label {
  margin: 0 !important;
}
.picker-label * input {
  width: 100% !important;
}

.react-datepicker {
  font-size: 1.3rem !important;
  font-family: "Poppins", sans-serif;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__current-month {
  font-size: 1.5rem !important;
  font-family: "Poppins", sans-serif;
}

.react-datepicker__header {
  padding-top: 6px !important;
}

.react-datepicker__navigation {
  top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
  margin: 0.5rem !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 92px !important;
}

.react-datepicker__time-container {
  width: 92px !important;
}

.react-datepicker__day--selected {
  background-color: #4765f0 !important;
  font-family: "Poppins", sans-serif;
}

.react-datepicker-time__header {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #4765f0;
  font-family: "Poppins", sans-serif;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background-color: #4765f0;
}

.react-datepicker__header {
  background-color: #eaf0fb;
}

.react-datepicker__year-select {
  padding-right: 25px;
}
