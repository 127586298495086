@import "../../variables.module.scss";

.salesClockSummary {
  // margin-top: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 100%;
  overflow: auto;
  max-height: 185px;
}

.salesClockSummarySaveBtn {
  margin-top: 2rem;
  margin-bottom: 1rem;
  width: 13em;
  display: flex;
  align-self: center;
}
