@import "../../variables.module.scss";
.main-price-change-log {
  /*  margin-left: 2.5rem;
  margin-right: 2.5rem;
  margin-top: 3rem;*/
  padding: 23px;
  padding-bottom: 135px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 35px;
  // border: black solid 1px;
}

.log-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dec-summ {
  margin: auto;
}

.status-container {
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.hr-title {
  color: #d9e3fa;
  height: 3px !important;
  opacity: 0.25;
  background-color: currentColor;
}

.revert-btn {
  font-size: 1.3rem;
  // padding-top: 0.25rem;
  border: none;
  font-weight: 600;
  cursor: default;
  background-color: white;
  pointer-events: none;
  white-space: nowrap;
}

.non-button {
  height: 30px;
}

.info-icon-sale-bpc {
  margin-left: -50px;
}

.info-icon-sale {
  margin-left: -50px;
}

.titleText {
  font-size: 1.3rem;
  font-weight: 400;
  flex-wrap: wrap;
}

.productImpactedText {
  font-size: 1.3rem;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;

  display: flex;
  align-items: center;
}

.productImpactedText-saleslog {
  margin-top: 0.75rem;
}

.created-at {
  font-size: 1.4rem;
}

.discount-value {
  font-weight: 600;
}

.log-btn-disable {
  border: none;
  background-image: linear-gradient(
    90deg,
    #494949,
    #696868 10%,
    #999898 75%,
    #bebebe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #494949,
    #696868 0,
    #999898 37%,
    #bebebe
  );
  height: 3.6rem;
  font-size: 1.3rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: none;
}

.log-btn-disable:hover {
  background-color: grey;
  cursor: none;
}

.log-btn {
  border: none;
  background-image: linear-gradient(
    90deg,
    #203eca,
    #2542ef 10%,
    #4b69f7 75%,
    #7190fe
  );
  background-image: -webkit-linear-gradient(
    90deg,
    #203eca,
    #2542ef 0,
    #4b69f7 37%,
    #7190fe
  );

  height: 3.6rem;
  font-size: 1.3rem;
  font-weight: 600;
  // padding: 10px 0px !important;
  border-radius: 5px;

  box-shadow: 0 20px 20px -12px rgba(23, 52, 251, 0.4);

  transition: transform 0.2s;
}

.log-btn:hover {
  //transform: scale(0.98);
  box-shadow: 0 25px 20px -10px rgba(23, 52, 251, 0.4);
}

.description {
  color: $icon-active-color;
  font-weight: 600;
  font-size: 1.3rem;
}

.log-label-status--left {
  margin-left: 11rem;
}

.log-label-status {
  margin-left: 60px;
}

.log-label-action {
  margin-left: 36px;
}

@media only screen and (max-width: 1100px) and (min-width: 700px) {
  .log-label-status {
    margin-left: 30px;
  }

  .log-label-action {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1600px) {
  .log-label-status {
    margin-left: 80px;
  }
  .log-label-action {
    margin-left: 50px !important;
  }
}

.white--space {
  white-space: nowrap;
}

.log-card {
  border-radius: 5px;
  padding: 5px 30px;
  height: 7.5rem;
  display: flex;
  justify-content: center;

  background-color: white;
  border-color: white;
  border-width: 0.25rem;

  // -webkit-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // -moz-box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);
  // box-shadow: 0px 15px 27px -3px rgba(159, 189, 237, 0.66);

  .hidden-label {
    display: none;
  }

  h4 {
    color: $icon-active-color;
    font-weight: 600;
    font-size: 1.3rem;
  }

  span {
    font-size: 1.3rem;
  }

  .progress-btn {
    font-size: 1.3rem;
    font-weight: 600;
    //text-align: initial;
    // margin-left: 4rem;
  }

  // .spinner-log-margin-right{
  //   margin-right: 2rem;
  // }

  .current-discount-status {
    font-size: 1.3rem;
    padding: 0.5rem 2rem;
    // width: 9rem;
    border-radius: 5px;
  }

  .sales-log-description-margin {
    margin-left: 1.5rem;
  }

  .sales-log-subdescription-margin {
    margin-left: 1.5rem;
  }

  .progress-bar {
    padding: 0;
    border-radius: 0.5rem;
  }

  .progress-bar-percentage {
    position: absolute;
    text-align: center;
    line-height: 3rem; /* line-height should be equal to bar height */
    overflow: hidden;
    color: black;
    font-weight: bold;
    font-size: 1.3rem;
    right: 0;
    left: 0;
  }

  .progress {
    position: relative;
    // display: flex;
    height: 3rem;
    font-size: 1.3rem;
    font-weight: 600;
    // overflow: hidden;
    // border-radius: 0.5rem;
    background-color: #4555e7;
    border: #4555e7 0.1rem solid;
  }

  .back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #5d6d7e;
    color: white;
  }

  .front {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    color: black;
    transition: clip-path 1s linear;
  }

  .current-discount-status--progress {
    width: 100%;
    background-color: #e1eaf2;
    color: #5f7690;
    border-radius: 3rem;
  }

  .bg-bar-color {
    background-color: $icon-active-color !important;
  }

  .current-discount-status--active {
    padding: 0.5rem 2.5rem;
    background-color: #c6f4be;
    color: #356d1b;
  }

  .current-discount-status--expired {
    background-color: #facbc1;
    color: #762b11;
  }

  .current-discount-status--filtering {
    background-color: #f9f5ca;
    color: #95977c;
  }

  .current-discount-status--inactive {
    background-color: #e1eaf2;
    color: #5f7690;
  }
  svg:hover {
    cursor: pointer;
  }
}

.action-margin-adjust {
  margin-left: -5rem;
}

.drag-handle {
  cursor: grab;
}
.drag-handle svg {
  cursor: grab;
}

.created_on_label {
  display: flex;
  justify-content: center;
}

.created_on_value {
  display: flex;
  justify-content: center;
  margin-left: -15px;
}

.inprogress-style {
  display: flex;
  justify-content: center;
}

.countdown {
  font-size: 12px;
  width: 100%;
  font-weight: 400;
  opacity: 0.7;
  text-align: center;
}

@media (min-width: 450px) {
  .responsive-margin {
    margin: auto;
  }
}

@media only screen and (min-width: 3400px) and (max-width: 3800px) {
  .inprogress-style {
    margin-right: 60px;
  }
}

@media only screen and (min-width: 3100px) and (max-width: 3400px) {
  .inprogress-style {
    margin-right: 46px;
  }
}

@media only screen and (min-width: 2700px) and (max-width: 3100px) {
  .inprogress-style {
    margin-right: 36px;
  }
}

@media only screen and (min-width: 2360px) and (max-width: 2700px) {
  .inprogress-style {
    margin-right: 18px;
  }
}

@media only screen and (min-width: 1110px) and (max-width: 1310px) {
  .inprogress-style {
    margin-right: -25px;
  }
}

@media only screen and (min-width: 810px) and (max-width: 1110px) {
  .inprogress-style {
    margin-right: -30px;
  }
}

@media only screen and (min-width: 575px) and (max-width: 810px) {
  .inprogress-style {
    margin-right: -35px;
  }

  .mobile-action {
    flex-wrap: nowrap !important;
  }
  .log-btn-disable {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 800px) {
  .discount-labels {
    display: none;
  }

  .log-card {
    height: 22rem;
    padding-top: 10px;
  }

  .log-card-extra {
    height: 29rem;
  }

  .discount-value {
    font-weight: normal;
  }

  .status-container {
    // flex-wrap: wrap;
    margin-left: 0rem;
    // margin-top: 1rem;
  }

  .log-content-margin-mobile {
    margin-bottom: 0.75rem;
  }

  .info-icon-sale {
    margin-left: 0;
  }
  .info-icon-bpc {
    margin-left: 0;
  }

  .productImpactedText {
    font-size: 1.4rem;
    font-weight: normal;
  }

  .label-mobile {
    font-weight: bold;
    color: #003b79;
    margin-right: 5px;
  }

  .description {
    color: black;
    font-weight: normal;
    font-size: 1.5rem;
  }

  .label-mobile-margin {
    margin-left: -1.5rem;
  }

  .label-mobile-priority {
    margin-bottom: 0.5rem;
  }

  .mobile-space {
    margin-right: 5px;
  }
  .created_on_value {
    display: flex;
    justify-content: flex-start !important;
    margin: 0 !important;
  }
  .inprogress-style {
    display: flex;
    justify-content: flex-start;
  }
  .countdown {
    text-align: left;
  }
}

@media only screen and (min-width: 800px) {
  .label-mobile {
    display: none;
  }
  .discount-labels {
    flex-wrap: nowrap;
  }
}

@media only screen and (max-width: 991px) {
  .log-btn {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .revert-btn {
    font-weight: 400;
  }

  .drag-handle {
    display: none;
  }
}

@media only screen and (min-width: 1680px) {
  .revert-btn {
    margin-right: 0.5rem;
    margin-right: 0.5rem;
  }

  // .revert-btn{
  //   padding-right: 2rem;
  // }
}

// .containerStyles{
//   height: 20,
//   width: '100%',
//   backgroundColor: "#e0e0de",
//   borderRadius: 50,
//   margin: 50
// }

// .fillerStyles{
//   height: '100%',
//   width: `${completed}%`,
//   backgroundColor: bgcolor,
//   borderRadius: 'inherit',
//   textAlign: 'right'
// }

// @media only screen and (max-width: 2048px) {
//   .revert-btn{
//     font-size: 1.4rem;
//     border: none;
//     font-weight: 600;
//     cursor:default;
//     background-color: white;
//     pointer-events: none;
// }
// }

// @media only screen and (max-width: 1535px) {
//   .revert-btn{
//     font-size: 1.2rem;
//     border: none;
//     font-weight: 600;
//     cursor:default;
//     background-color: white;
//     pointer-events: none;
// }
// }

// @media only screen and (max-width: 1024px) {
//   .revert-btn{
//     font-size: 0.7rem;
//     border: none;
//     font-weight: 400;
//     cursor:default;
//     background-color: white;
//     pointer-events: none;
// }
// }
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

@media only screen and (max-width: 886px) and (min-width: 800px) {
  .info-icon-bpc {
    margin-left: 24px !important;
  }
  .info-icon-sale {
    margin-left: 44px !important;
  }
}
